import { Actions } from '../action';

const initialState = {
    isLoading: false,
    adminOnboardList: [],
    adminOnboardActiveList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const adminOnboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_ADMIN_ON_BOARD: {
            return commonState(state);
        }
        case Actions.GET_ADMIN_ON_BOARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                adminOnboardList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_ADMIN_ON_BOARD_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_ADMIN_ON_BOARD: {
            return commonState(state);
        }
        case Actions.CREATE_ADMIN_ON_BOARD_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_ADMIN_ON_BOARD_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_ADMIN_ON_BOARD: {
            return commonState(state);
        }
        case Actions.UPDATE_ADMIN_ON_BOARD_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_ADMIN_ON_BOARD_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_ADMIN_ON_BOARD: {
            return commonState(state);
        }
        case Actions.DELETE_ADMIN_ON_BOARD_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_ADMIN_ON_BOARD_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_ADMIN_ON_BOARD: {
            return commonState(state);
        }
        case Actions.ACTIVE_ADMIN_ON_BOARD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                adminOnboardActiveList: action?.data,
                error: false
            };
        }
        case Actions.ACTIVE_ADMIN_ON_BOARD_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const adminOnboardList = (state) => state?.ADMIN_ON_BOARD?.adminOnboardList;
export const adminOnboardActiveList = (state) => state?.ADMIN_ON_BOARD?.adminOnboardActiveList;
export const totalItemCount = (state) => state?.ADMIN_ON_BOARD?.totalCount;
export const adminOnboardListLoadingSelector = state => state?.ADMIN_ON_BOARD?.isLoading;


