import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { highValueItem, highVolumeItem } from "../redux/reducer/dashboardReducer";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "High Value Product and High Volume Product Chart",
    },
  },
};


const LineChartHorizontal = ({ selectedTrending, filter }) => {
  const highValueProduct = useSelector(highValueItem)
  const highVolumeProduct = useSelector(highVolumeItem)
  const hightValueProductLable = highValueProduct?.map((obj) => (obj?.product_name))
  const hightValueProductData = highValueProduct?.map((obj) => (obj?.product_price))
  const hightVolumeProductLable = highVolumeProduct?.map((obj) => (obj?.product_name))
  const hightVolumeProductData = highVolumeProduct?.map((obj) => (obj?.product_count))
  let data = {
    labels: selectedTrending === 'Value' ? hightValueProductLable : hightVolumeProductLable,
    datasets: [
      {
        label: selectedTrending === 'Value' ? "Price" : "Count",
        data: selectedTrending === 'Value' ? hightValueProductData : hightVolumeProductData,
        borderColor: "rgb(75, 192, 192)", // Teal border color
        backgroundColor: "rgba(75, 192, 192, 0.5)", // Semi-transparent teal background color
      },
    ],
  };
  return <Bar options={options} data={data} />

}

export default LineChartHorizontal;
