import { doAction, doResponseAction } from ".";

const GET_NOTIFICATION = 'GET_NOTIFICATION';
const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';


const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

const ACTIVE_NOTIFICATION = 'ACTIVE_NOTIFICATION';
const ACTIVE_NOTIFICATION_SUCCESS = 'ACTIVE_NOTIFICATION_SUCCESS';
const ACTIVE_NOTIFICATION_FAILURE = 'ACTIVE_NOTIFICATION_FAILURE';

export {
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    
    CREATE_NOTIFICATION,
    CREATE_NOTIFICATION_SUCCESS,
    CREATE_NOTIFICATION_FAILURE,

    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,

    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,

    ACTIVE_NOTIFICATION,
    ACTIVE_NOTIFICATION_SUCCESS,
    ACTIVE_NOTIFICATION_FAILURE,
}

export const getNotification = (params, callback) => doAction(GET_NOTIFICATION, params, callback);
export const getNotificationSuccess = (data) => doResponseAction(GET_NOTIFICATION_SUCCESS, data);
export const getNotificationFailure = (data) => doResponseAction(GET_NOTIFICATION_FAILURE, data);

export const createNotification = (params, callback) => doAction(CREATE_NOTIFICATION, params, callback);
export const createNotificationSuccess = (data) => doResponseAction(CREATE_NOTIFICATION_SUCCESS, data);
export const createNotificationFailure = (data) => doResponseAction(CREATE_NOTIFICATION_FAILURE, data);

export const updateNotification = (params, callback) => doAction(UPDATE_NOTIFICATION, params, callback);
export const updateNotificationSuccess = (data) => doResponseAction(UPDATE_NOTIFICATION_SUCCESS, data);
export const updateNotificationFailure = (data) => doResponseAction(UPDATE_NOTIFICATION_FAILURE, data);

export const deleteNotification = (params, callback) => doAction(DELETE_NOTIFICATION, params, callback);
export const deleteNotificationSuccess = (data) => doResponseAction(DELETE_NOTIFICATION_SUCCESS, data);
export const deleteNotificationFailure = (data) => doResponseAction(DELETE_NOTIFICATION_FAILURE, data);

export const activeNotification = (params, callback) => doAction(ACTIVE_NOTIFICATION, params, callback);
export const activeNotificationSuccess = (data) => doResponseAction(ACTIVE_NOTIFICATION_SUCCESS, data);
export const activeNotificationFailure = (data) => doResponseAction(ACTIVE_NOTIFICATION_FAILURE, data);
