import { Actions } from '../action';

const initialState = {
    isLoading: false,
    categoryList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const categoryReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_CATEGORY: {
            return commonState(state);
        }
        case Actions.GET_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                categoryList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_CATEGORY_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_CATEGORY: {
            return commonState(state);
        }
        case Actions.CREATE_CATEGORY_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_CATEGORY_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_CATEGORY: {
            return commonState(state);
        }
        case Actions.UPDATE_CATEGORY_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_CATEGORY_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_CATEGORY: {
            return commonState(state);
        }
        case Actions.DELETE_CATEGORY_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_CATEGORY_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_CATEGORY: {
            return commonState(state);
        }
        case Actions.ACTIVE_CATEGORY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                categoryActiveList: action?.data,
                error: false
            };
        }
        case Actions.ACTIVE_CATEGORY_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const categoryList = (state) => state?.CATEGORY?.categoryList;
export const categoryActiveList = (state) => state?.CATEGORY?.categoryActiveList;
export const totalItemCount = (state) => state?.CATEGORY?.totalCount;
export const categoryListLoadingSelector = state => state?.CATEGORY?.isLoading;


