import moment from 'moment/moment';
import { getWeeksInMonth, userCurrentZone } from './SuspiciousOverview';

export let timeLabelsIn24 = [
    "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00",
    "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
    "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
];
export let timeLabelIn12 = timeLabelsIn24.map((obj) => {
    const [hours, minutes] = obj.split(':');
    const hourInt = parseInt(hours, 10);
    return `${hourInt <= 12 ? ((hourInt == "00.00") ? 12 : hourInt) : hourInt - 12}:${minutes} ${hourInt < 12 ? 'AM' : 'PM'}`;
});

export function filterByTime(response) {
    const responseMap = new Map();
    for (const entry of response) {
        const current_time = userCurrentZone(entry)
        const startTime = current_time?.split(":");
        const hourKey = `${startTime?.[0]}:00`
        let totalSavedCount = 0;
        for (const data of response) {
            const current_time = userCurrentZone(data)
            const time = current_time?.split(":");
            if (time?.[0] == startTime?.[0]) {
                totalSavedCount += data?.saved_count;
            }
        }
        if (!responseMap.has(hourKey)) {
            responseMap.set(hourKey, {
                "start_time__time": hourKey,
                "saved_count": totalSavedCount,
            });
        }
    }
    const zeroedData = [];
    for (const label of timeLabelsIn24) {
        if (responseMap?.has(label)) {
            zeroedData?.push(responseMap.get(label));
        } else {
            zeroedData?.push({
                "start_time__time": label,
                "saved_count": 0,
            });
        }
    }
    return zeroedData;
}

export let dayLabels = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday",
];

export function filterByday(response) {
    const responseMap = new Map();
    for (const entry of response) {
        const dayKey = moment(entry?.time_period).format('dddd')
        if (!responseMap?.has(dayKey)) {
            responseMap?.set(dayKey, {
                "time_period": dayKey,
                "saved_count": entry?.saved_count,
            });
        }
    }
    const zeroedData = [];
    for (const label of dayLabels) {
        if (responseMap?.has(label)) {
            zeroedData?.push(responseMap?.get(label));
        } else {
            zeroedData?.push({
                "time_period": label,
                "saved_count": 0,
            });
        }
    }
    return zeroedData;
}

export let WeekLabels = getWeeksInMonth();

export function getWeekOfMonth(weekCount, year) {
    // Create a new Date object for the first day of the specified year and month
    const startDate = new Date(year, 0, 1);

    // Calculate the day of the week for the first day (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = startDate.getDay();

    // Calculate the number of days to add to reach the start of the first week
    const daysToAdd = (weekCount - 1) * 7 - dayOfWeek;

    // Calculate the start date of the specified week
    startDate.setDate(1 + daysToAdd);

    // Calculate the week number within the month (rounded up)
    const weekNumber = Math.ceil(startDate.getDate() / 7);

    return `${"Week " + weekNumber}`;
}
export function filterByWeek(response) {
    const responseMap = new Map();
    for (const entry of response) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const weekKey = getWeekOfMonth(entry?.time_period, year)
        if (!responseMap?.has(weekKey)) {
            responseMap?.set(weekKey, {
                "time_period": weekKey,
                "saved_count": entry?.saved_count,
            });
        }
    }
    const zeroedData = [];
    for (const label of WeekLabels) {
        if (responseMap?.has(label)) {
            zeroedData?.push(responseMap?.get(label));
        } else {
            zeroedData?.push({
                "time_period": label,
                "saved_count": 0,
            });
        }
    }
    return zeroedData;
}

export let MonthLabels = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export function filterByMonth(response) {
    const responseMap = new Map();
    for (const entry of response) {
        const weekKey = moment().month(entry?.time_period - 1).format("MMMM")
        if (!responseMap?.has(weekKey)) {
            responseMap?.set(weekKey, {
                "time_period": weekKey,
                "saved_count": entry?.saved_count,
            });
        }
    }
    const zeroedData = [];
    for (const label of MonthLabels) {
        if (responseMap?.has(label)) {
            zeroedData?.push(responseMap?.get(label));
        } else {
            zeroedData?.push({
                "time_period": label,
                "saved_count": 0,
            });
        }
    }
    return zeroedData;
}


const dateArray = [];
for (let i = 0; i <= 6; i++) {
    const date = moment().clone().subtract(i, 'days');
    dateArray.push(date);
}
export const lastWeekDay = dateArray?.map(date => date?.format('dddd')).reverse();

export function filterBydayWithLastWeek(response) {
    const responseMap = new Map();
    for (const entry of response) {
        const dayKey = moment(entry?.time_period).format('dddd')
        if (!responseMap?.has(dayKey)) {
            responseMap?.set(dayKey, {
                "time_period": dayKey,
                "saved_count": entry?.saved_count,
            });
        }
    }
    const zeroedData = [];
    for (const label of lastWeekDay) {
        if (responseMap?.has(label)) {
            zeroedData?.push(responseMap?.get(label));
        } else {
            zeroedData?.push({
                "time_period": label,
                "saved_count": 0,
            });
        }
    }
    return zeroedData;
}