import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import { Actions } from '../redux/action';

const StoreTypeAhead = ({
    defaultOptions = null,
    placeholder = '',
    onChange = (value) => value,
    isMulti = false,
    ...props
}) => {
    const [options, setOptions] = useState(defaultOptions || []);
    const dispatch = useDispatch();

    const optionsRef = useRef()

    const searchApi = useCallback((value) => {
        const defaultFilter = {
            search: value,
            limit: 100
        };
        dispatch(Actions.activeStore(defaultFilter, (resp) => {
            if (resp?.response_code !== 0) {
                return
            } else {
                let data = [];
                data = resp?.response?.map(d => ({ label: d.name, value: d.id }))
                // setIsLoading(false)
                setOptions(data)
                optionsRef.current = data
            }

        }))
    }, [dispatch]);

    const debounceRef = useRef(debounce((value) => {
        searchApi(value);
    }, 500));

    useEffect(() => {
        searchApi();
    }, [dispatch, searchApi])


    return <Select
        className="title-case"
        {...props}
        placeholder={placeholder}
        // isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default StoreTypeAhead;