import { doAction, doResponseAction } from ".";

const GET_EDGE = 'GET_EDGE';
const GET_EDGE_SUCCESS = 'GET_EDGE_SUCCESS';
const GET_EDGE_FAILURE = 'GET_EDGE_FAILURE';


const CREATE_EDGE = 'CREATE_EDGE';
const CREATE_EDGE_SUCCESS = 'CREATE_EDGE_SUCCESS';
const CREATE_EDGE_FAILURE = 'CREATE_EDGE_FAILURE';

const UPDATE_EDGE = 'UPDATE_EDGE';
const UPDATE_EDGE_SUCCESS = 'UPDATE_EDGE_SUCCESS';
const UPDATE_EDGE_FAILURE = 'UPDATE_EDGE_FAILURE';

const DELETE_EDGE = 'DELETE_EDGE';
const DELETE_EDGE_SUCCESS = 'DELETE_EDGE_SUCCESS';
const DELETE_EDGE_FAILURE = 'DELETE_EDGE_FAILURE';

const ACTIVE_EDGE = 'ACTIVE_EDGE';
const ACTIVE_EDGE_SUCCESS = 'ACTIVE_EDGE_SUCCESS';
const ACTIVE_EDGE_FAILURE = 'ACTIVE_EDGE_FAILURE';

export {
    GET_EDGE,
    GET_EDGE_SUCCESS,
    GET_EDGE_FAILURE,
    
    CREATE_EDGE,
    CREATE_EDGE_SUCCESS,
    CREATE_EDGE_FAILURE,

    UPDATE_EDGE,
    UPDATE_EDGE_SUCCESS,
    UPDATE_EDGE_FAILURE,

    DELETE_EDGE,
    DELETE_EDGE_SUCCESS,
    DELETE_EDGE_FAILURE,

    ACTIVE_EDGE,
    ACTIVE_EDGE_SUCCESS,
    ACTIVE_EDGE_FAILURE,
}

export const getEdge = (params, callback) => doAction(GET_EDGE, params, callback);
export const getEdgeSuccess = (data) => doResponseAction(GET_EDGE_SUCCESS, data);
export const getEdgeFailure = (data) => doResponseAction(GET_EDGE_FAILURE, data);

export const createEdge = (params, callback) => doAction(CREATE_EDGE, params, callback);
export const createEdgeSuccess = (data) => doResponseAction(CREATE_EDGE_SUCCESS, data);
export const createEdgeFailure = (data) => doResponseAction(CREATE_EDGE_FAILURE, data);

export const updateEdge = (params, callback) => doAction(UPDATE_EDGE, params, callback);
export const updateEdgeSuccess = (data) => doResponseAction(UPDATE_EDGE_SUCCESS, data);
export const updateEdgeFailure = (data) => doResponseAction(UPDATE_EDGE_FAILURE, data);

export const deleteEdge = (params, callback) => doAction(DELETE_EDGE, params, callback);
export const deleteEdgeSuccess = (data) => doResponseAction(DELETE_EDGE_SUCCESS, data);
export const deleteEdgeFailure = (data) => doResponseAction(DELETE_EDGE_FAILURE, data);

export const activeEdge = (params, callback) => doAction(ACTIVE_EDGE, params, callback);
export const activeEdgeSuccess = (data) => doResponseAction(ACTIVE_EDGE_SUCCESS, data);
export const activeEdgeFailure = (data) => doResponseAction(ACTIVE_EDGE_FAILURE, data);