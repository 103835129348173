import { Actions } from '../action';

const initialState = {
    isLoading: false,
    storeList: [],
    storeActiveList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const storeReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_STORE: {
            return commonState(state);
        }
        case Actions.GET_STORE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                storeList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_STORE_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_STORE: {
            return commonState(state);
        }
        case Actions.CREATE_STORE_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_STORE_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_STORE: {
            return commonState(state);
        }
        case Actions.UPDATE_STORE_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_STORE_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_STORE: {
            return commonState(state);
        }
        case Actions.DELETE_STORE_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_STORE_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_STORE: {
            return commonState(state);
        }
        case Actions.ACTIVE_STORE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                storeActiveList: action?.data,
                error: false
            };
        }
        case Actions.ACTIVE_STORE_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const storeList = (state) => state?.STORE?.storeList;
export const storeActiveList = (state) => state?.STORE?.storeActiveList;
export const totalItemCount = (state) => state?.STORE?.totalCount;
export const storeListLoadingSelector = state => state?.STORE?.isLoading;


