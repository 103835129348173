import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_NOTIFICATION, UPDATE_NOTIFICATION,DELETE_NOTIFICATION,GET_NOTIFICATION,ACTIVE_NOTIFICATION } from '../action/notificationAction';

export function* getNotification({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_NOTIFICATION, params)
        yield put(Actions.getNotificationSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getNotificationFailure(error))
    }
}
export function* createNotification({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_NOTIFICATION, params)
        yield put(Actions.createNotificationSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createNotificationFailure(error))
    }
}
export function* updateNotification({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_NOTIFICATION, params)
        yield put(Actions.updateNotificationSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateNotificationFailure(error))
    }
}
export function* deleteNotification({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_NOTIFICATION, params)
        yield put(Actions.deleteNotificationSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteNotificationFailure(error))
    }
}
export function* activeNotification({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_NOTIFICATION, params)
        yield put(Actions.activeNotificationSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeNotificationFailure(error))
    }
}
export default function* notificationWatcher() {
    yield all([
        takeLatest(GET_NOTIFICATION, getNotification),
        takeLatest(CREATE_NOTIFICATION, createNotification),
        takeLatest(UPDATE_NOTIFICATION, updateNotification),
        takeLatest(DELETE_NOTIFICATION, deleteNotification),
        takeLatest(ACTIVE_NOTIFICATION, activeNotification),

    ]);
}
