import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import { Navigate, Outlet } from "react-router-dom"
import { store } from "../../redux/store";

const Layout = () => {
    const tokendata = store?.getState()?.USER;
    const { token } = tokendata;
    return (
        <>
            {token ? <div className="vision_dashboard">
                <div className="vision_dashboard_content">
                    <Sidebar />
                    <Header />
                    <Outlet />
                    <Footer />
                </div>
            </div> : <Navigate to='/' />}
        </>
    )
}

export default Layout