import { Actions } from '../action';

const initialState = {
    isLoading: false,
    vendorProductList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const vendorProductReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_VENDOR_PRODUCT: {
            return commonState(state);
        }
        case Actions.GET_VENDOR_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorProductList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_VENDOR_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_VENDOR_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_VENDOR_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_VENDOR_PRODUCT: {
            return commonState(state);
        }
        case Actions.UPDATE_VENDOR_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_VENDOR_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_VENDOR_PRODUCT: {
            return commonState(state);
        }
        case Actions.DELETE_VENDOR_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_VENDOR_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_VENDOR_PRODUCT: {
            return commonState(state);
        }
        case Actions.ACTIVE_VENDOR_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.ACTIVE_VENDOR_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_VENDOR_PRODUCT: {
            return commonState(state);
        }
        default:
            return state;
    }
};



export const vendorProductList = (state) => state?.VENDOR_PRODUCT?.vendorProductList;
export const totalItemCount = (state) => state?.VENDOR_PRODUCT?.totalCount;
export const vendorProductListLoadingSelector = state => state?.VENDOR_PRODUCT?.isLoading;


