import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { storeDetailsValidateField } from "../utils/Validation";
import { storeList, totalItemCount } from '../redux/reducer/storeReducer';
import { toast } from "react-toastify";
import { PaginationComponent } from "../component/Pagination";
import AppLoader from "../component/AppLoader";
import { DeclinePopup } from "../component/DeclinePopup";
import { getLoginData } from "../redux/reducer/loginReducer";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import MESSAGE from "../constant/String";


const Store = () => {
    const dispatch = useDispatch()
    const storeListData = useSelector(storeList)
    const totalCount = useSelector(totalItemCount)
    const userData = useSelector(getLoginData)

    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [showAddList, setShowAddList] = useState(false);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [selectSearch, setSelectedSearch] = useState()
    const [storeDetails, setStoreDetails] = useState({
        name: "",
        email: "",
        address: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        contact_no: ""
    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        email: false,
        address: false,
        city: false,
        state: false,
        country: false,
        zipcode: false,
        contact_no: false
    });


    const getStoreList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            search: selectSearch
        }
        setLoading(true)
        dispatch(Actions.getStore(params, callback))
    }, [dispatch, pageNumber, selectSearch])

    useEffect(() => {
        getStoreList()
    }, [getStoreList])

    const handleFieldChange = (field, value) => {
        setStoreDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleAddButtonClick = () => {
        setShowAddList(true);
        setStoreDetails({
            name: "",
            email: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            contact_no: ""
        })
    };
    const handleBackButtonClick = () => {
        setShowAddList(false);
        setStoreDetails({
            name: "",
            email: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            contact_no: ""
        })
        setFieldChangedStatus(() => ({
            name: false,
            email: false,
            address: false,
            city: false,
            state: false,
            country: false,
            zipcode: false,
            contact_no: false
        }));
    };
    const onEdit = (obj) => {
        const { name, email, address, city, state, country, zipcode, contact_no, id } = obj
        let countryData = Country?.getAllCountries()?.find(obj => obj?.name === country)
        let stateData = State?.getStatesOfCountry(countryData?.isoCode)?.find(obj => obj?.name === state)
        let cityData = City?.getCitiesOfState(stateData?.countryCode, stateData?.isoCode)?.find(obj => obj?.name === city)
        setShowAddList(true);
        setStoreDetails({
            id: id,
            name: name,
            email: email,
            address: address,
            city: cityData,
            state: stateData,
            country: countryData,
            zipcode: zipcode,
            contact_no: contact_no
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        for (const field in storeDetails) {
            if (storeDetailsValidateField(field, storeDetails?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);

        if (!hasErrors) {
            setLoading(true)
            const callback = (response) => {
                setLoading(false)
                if (response?.response_code === 0) {
                    handleBackButtonClick()
                    getStoreList();
                    toast.success(response?.response_message)
                } else {
                    toast.error(response?.response_message)
                }
            };
            let params = {
                id: storeDetails?.id,
                name: storeDetails?.name,
                email: storeDetails?.email,
                address: storeDetails.address,
                city: storeDetails?.city?.name,
                state: storeDetails?.state?.name,
                country: storeDetails?.country?.name,
                zipcode: storeDetails?.zipcode,
                contact_no: storeDetails?.contact_no
            }
            if (storeDetails?.id) {
                dispatch(Actions.updateStore(params, callback))
            } else {
                delete params.id;
                dispatch(Actions.createStore(params, callback))
            }

        }
    };
    const activeStore = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick();
                getStoreList();
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "store_id": itemObj?.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteStore(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (obj) => {
        setItemObj(obj);
        setOpenDeclineModal(true);
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const handleCountrySelect = (selected) => {
        setStoreDetails({ ...storeDetails, 'country': selected, 'state': "", 'city': "" })
    };
    const handleStateSelect = (selected) => {
        setStoreDetails({ ...storeDetails, 'state': selected, 'city': "" })
    };
    const handleCitySelect = (selected) => {
        setStoreDetails({ ...storeDetails, 'city': selected })
    };
    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard">
                {isLoading && <AppLoader />}
                {!showAddList && <div className="vision_dashboard_content">
                    <div className="right_side right_side_mid right_side_cover ">
                        <div className="body_content">
                            <div className="body_header">
                                <p className="m-0 title">Store</p>
                                <div className="d-flex gap-2">
                                    <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(false)}>Store list</p>
                                </div>
                            </div>
                            {/* <!-- add button start --> */}
                            <div className="d-flex justify-content-between my-4">
                                <div className="d-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search store name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                </div>
                                {userData?.role_id !== 3 && <button className="add_btn" onClick={() => handleAddButtonClick()}><p className="m-0">Add</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                </button>}
                            </div>
                            {/* <!-- add button end --> */}
                            {/* <!-- table start --> */}
                            <div className="table_bg">
                                <div className="table_body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Store Name</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Contact Number</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(storeListData) && storeListData?.map((obj, index) => (
                                                <tr key={obj?.id}>
                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                    <td>{obj?.name}</td>
                                                    <td>{obj?.address}</td>
                                                    <td>{obj?.contact_no}</td>
                                                    <td>{obj?.is_active ? <p className="m-0 status_td">Active</p> : <p className="m-0 inactive_td">In Active</p>}</td>
                                                    <td>
                                                        <div className="action_td">
                                                            <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    strokeWidth="1.5" stroke="#1a9f53" width="16" height="16" >
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                                </svg>
                                                            </button>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckChecked"
                                                                    checked={obj?.is_active}
                                                                    onClick={(e) => handleClick(obj)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {Array.isArray(storeListData) && storeListData?.length == 0 && (
                                                <tr className="tr-shadow">
                                                    <td colSpan="6" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {Array.isArray(storeListData) && storeListData?.length > 0 &&
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                    </div>}
                            </div>
                            {/* <!-- table end --> */}
                        </div>
                    </div>
                </div>}
                {showAddList && <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Store</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 pointer-hover" onClick={() => handleBackButtonClick()}>Store list</p>
                                        <p className="m-0">~</p>
                                        <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(true)}> {storeDetails?.id ? "Update Store" : "Create Store"}</p>
                                    </div>
                                </div>
                                {/* <!-- Navigation Button end --> */}
                                {/* <!-- Store Info Start --> */}
                                <div className="list_form mt-4">
                                    <div className="d-flex justify-content-between align-items-center"><p className="m-0 table_heading">Store Information</p>
                                        <button className="add_btn" onClick={() => handleBackButtonClick()}><p className="m-0">Back</p></button>
                                    </div>
                                    <div className="row g-3 mt-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Store Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter store name"
                                                value={storeDetails?.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.name && storeDetailsValidateField('name', storeDetails?.name) && (
                                                <span className="text-danger">{storeDetailsValidateField('name', storeDetails?.name)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputPassword4" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email ID"
                                                value={storeDetails?.email}
                                                onChange={(e) => handleFieldChange('email', e.target.value)}
                                            />
                                            {fieldChangedStatus?.email && storeDetailsValidateField('email', storeDetails?.email) && (
                                                <span className="text-danger">{storeDetailsValidateField('email', storeDetails?.email)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputPassword4" className="form-label">Address</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder="Address"
                                                value={storeDetails?.address}
                                                onChange={(e) => handleFieldChange('address', e?.target?.value)}
                                                maxLength={150}
                                            />
                                            {fieldChangedStatus?.address && storeDetailsValidateField('address', storeDetails?.address) && (
                                                <span className="text-danger">{storeDetailsValidateField('address', storeDetails?.address)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputAddress" className="form-label">Country</label>
                                            <Select
                                                options={Country.getAllCountries()}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={storeDetails?.country}
                                                onChange={(item) => {
                                                    handleCountrySelect(item);
                                                }}
                                            />
                                            {fieldChangedStatus?.country && storeDetailsValidateField('country', storeDetails?.country) && (
                                                <span className="text-danger">{storeDetailsValidateField('country', storeDetails?.country)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputAddress" className="form-label">State</label>
                                            <Select
                                                options={State?.getStatesOfCountry(storeDetails?.country?.isoCode)}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={storeDetails?.state}
                                                onChange={(item) => {
                                                    handleStateSelect(item);
                                                }}
                                            />
                                            {fieldChangedStatus?.state && storeDetailsValidateField('state', storeDetails?.state) && (
                                                <span className="text-danger">{storeDetailsValidateField('state', storeDetails?.state)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputAddress" className="form-label">City</label>
                                            <Select
                                                options={City?.getCitiesOfState(storeDetails?.state?.countryCode, storeDetails?.state?.isoCode)}
                                                getOptionLabel={(options) => {
                                                    return options["name"];
                                                }}
                                                getOptionValue={(options) => {
                                                    return options["name"];
                                                }}
                                                value={storeDetails?.city}
                                                onChange={(item) => {
                                                    handleCitySelect(item);
                                                }}
                                            />
                                            {fieldChangedStatus?.city && storeDetailsValidateField('city', storeDetails?.city) && (
                                                <span className="text-danger">{storeDetailsValidateField('city', storeDetails?.city)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputAddress" className="form-label">Zipcode</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="zipcode"
                                                value={storeDetails?.zipcode}
                                                onChange={(e) => handleFieldChange('zipcode', e.target.value)}
                                                maxLength={6}
                                            />
                                            {fieldChangedStatus?.zipcode && storeDetailsValidateField('zipcode', storeDetails?.zipcode) && (
                                                <span className="text-danger">{storeDetailsValidateField('zipcode', storeDetails?.zipcode)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputAddress" className="form-label">Contact Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Contact Number"
                                                value={storeDetails?.contact_no}
                                                onChange={(e) => handleFieldChange('contact_no', e.target.value)}
                                                maxLength={10}
                                            />

                                            {fieldChangedStatus?.contact_no && storeDetailsValidateField('contact_no', storeDetails?.contact_no) && (
                                                <span className="text-danger">{storeDetailsValidateField('contact_no', storeDetails?.contact_no)}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 col-4 mx-auto mt-4">
                                        <button onClick={handleSubmit} className="upload_btn" type="button">{storeDetails?.id ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                {/* <!-- Store Info end --> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => activeStore()}
                />}
            </div>
        </React.Fragment>
    )
}

export default Store