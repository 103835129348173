import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_KIOSK, UPDATE_KIOSK,DELETE_KIOSK,GET_KIOSK,ACTIVE_KIOSK } from '../action/kioskAction';

export function* getKiosk({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_KIOSK, params)
        yield put(Actions.getKioskSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getKioskFailure(error))
    }
}
export function* createKiosk({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_KIOSK, params)
        yield put(Actions.createKioskSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createKioskFailure(error))
    }
}
export function* updateKiosk({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_KIOSK, params)
        yield put(Actions.updateKioskSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateKioskFailure(error))
    }
}
export function* deleteKiosk({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_KIOSK, params)
        yield put(Actions.deleteKioskSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteKioskFailure(error))
    }
}

export function* activeKiosk({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_KIOSK, params)
        yield put(Actions.activeKioskSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeKioskFailure(error))
    }
}
export default function* kioskWatcher() {
    yield all([
        takeLatest(GET_KIOSK, getKiosk),
        takeLatest(CREATE_KIOSK, createKiosk),
        takeLatest(UPDATE_KIOSK, updateKiosk),
        takeLatest(DELETE_KIOSK, deleteKiosk),
        takeLatest(ACTIVE_KIOSK, activeKiosk),
    ]);
}
