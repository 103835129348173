import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_ADMIN_ON_BOARD, UPDATE_ADMIN_ON_BOARD,DELETE_ADMIN_ON_BOARD,GET_ADMIN_ON_BOARD,ACTIVE_ADMIN_ON_BOARD } from '../action/adminOnboardAction';

export function* getAdminOnboard({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_ADMIN_ON_BOARD, params)
        yield put(Actions.getAdminOnboardSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getAdminOnboardFailure(error))
    }
}
export function* createAdminOnboard({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_ADMIN_ON_BOARD, params)
        yield put(Actions.createAdminOnboardSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createAdminOnboardFailure(error))
    }
}
export function* updateAdminOnboard({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_ADMIN_ON_BOARD, params)
        yield put(Actions.updateAdminOnboardSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateAdminOnboardFailure(error))
    }
}
export function* deleteAdminOnboard({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_ADMIN_ON_BOARD, params)
        yield put(Actions.deleteAdminOnboardSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteAdminOnboardFailure(error))
    }
}

export function* activeAdminOnboard({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_ADMIN_ON_BOARD, params)
        yield put(Actions.activeAdminOnboardSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeAdminOnboardFailure(error))
    }
}
export default function* adminOnboardWatcher() {
    yield all([
        takeLatest(GET_ADMIN_ON_BOARD, getAdminOnboard),
        takeLatest(CREATE_ADMIN_ON_BOARD, createAdminOnboard),
        takeLatest(UPDATE_ADMIN_ON_BOARD, updateAdminOnboard),
        takeLatest(DELETE_ADMIN_ON_BOARD, deleteAdminOnboard),
        takeLatest(ACTIVE_ADMIN_ON_BOARD, activeAdminOnboard),

    ]);
}
