import { doAction, doResponseAction } from ".";

const GET_STORE = 'GET_STORE';
const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
const GET_STORE_FAILURE = 'GET_STORE_FAILURE';


const CREATE_STORE = 'CREATE_STORE';
const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
const CREATE_STORE_FAILURE = 'CREATE_STORE_FAILURE';

const UPDATE_STORE = 'UPDATE_STORE';
const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
const UPDATE_STORE_FAILURE = 'UPDATE_STORE_FAILURE';

const DELETE_STORE = 'DELETE_STORE';
const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
const DELETE_STORE_FAILURE = 'DELETE_STORE_FAILURE';

const ACTIVE_STORE = 'ACTIVE_STORE';
const ACTIVE_STORE_SUCCESS = 'ACTIVE_STORE_SUCCESS';
const ACTIVE_STORE_FAILURE = 'ACTIVE_STORE_FAILURE';

export {
    GET_STORE,
    GET_STORE_SUCCESS,
    GET_STORE_FAILURE,
    
    CREATE_STORE,
    CREATE_STORE_SUCCESS,
    CREATE_STORE_FAILURE,

    UPDATE_STORE,
    UPDATE_STORE_SUCCESS,
    UPDATE_STORE_FAILURE,

    DELETE_STORE,
    DELETE_STORE_SUCCESS,
    DELETE_STORE_FAILURE,

    ACTIVE_STORE,
    ACTIVE_STORE_SUCCESS,
    ACTIVE_STORE_FAILURE,
}

export const getStore = (params, callback) => doAction(GET_STORE, params, callback);
export const getStoreSuccess = (data) => doResponseAction(GET_STORE_SUCCESS, data);
export const getStoreFailure = (data) => doResponseAction(GET_STORE_FAILURE, data);

export const createStore = (params, callback) => doAction(CREATE_STORE, params, callback);
export const createStoreSuccess = (data) => doResponseAction(CREATE_STORE_SUCCESS, data);
export const createStoreFailure = (data) => doResponseAction(CREATE_STORE_FAILURE, data);

export const updateStore = (params, callback) => doAction(UPDATE_STORE, params, callback);
export const updateStoreSuccess = (data) => doResponseAction(UPDATE_STORE_SUCCESS, data);
export const updateStoreFailure = (data) => doResponseAction(UPDATE_STORE_FAILURE, data);

export const deleteStore = (params, callback) => doAction(DELETE_STORE, params, callback);
export const deleteStoreSuccess = (data) => doResponseAction(DELETE_STORE_SUCCESS, data);
export const deleteStoreFailure = (data) => doResponseAction(DELETE_STORE_FAILURE, data);

export const activeStore = (params, callback) => doAction(ACTIVE_STORE, params, callback);
export const activeStoreSuccess = (data) => doResponseAction(ACTIVE_STORE_SUCCESS, data);
export const activeStoreFailure = (data) => doResponseAction(ACTIVE_STORE_FAILURE, data);