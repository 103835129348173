import { Actions } from '../action';

const initialState = {
    isLoading: false,
    cameraList: [],
    cameraActiveList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const cameraReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_CAMERA: {
            return commonState(state);
        }
        case Actions.GET_CAMERA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cameraList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_CAMERA_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_CAMERA: {
            return commonState(state);
        }
        case Actions.CREATE_CAMERA_SUCCESS: {
            return commonSuccessForCreateUpdate(action);
        }
        case Actions.CREATE_CAMERA_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_CAMERA: {
            return commonState(state);
        }
        case Actions.UPDATE_CAMERA_SUCCESS: {
            return commonSuccessForCreateUpdate(action);
        }
        case Actions.UPDATE_CAMERA_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_CAMERA: {
            return commonState(state);
        }
        case Actions.DELETE_CAMERA_SUCCESS: {
            return commonSuccessForCreateUpdate(action);
        }
        case Actions.DELETE_CAMERA_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_CAMERA: {
            return commonState(state);
        }
        case Actions.ACTIVE_CAMERA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cameraActiveList: action?.data,
                error: false
            };
        }
        case Actions.ACTIVE_CAMERA_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const cameraList = (state) => state?.CAMERA?.cameraList;
export const cameraActiveList = (state) => state?.CAMERA?.cameraActiveList;
export const totalItemCount = (state) => state?.CAMERA?.totalCount;
export const cameraListLoadingSelector = state => state?.CAMERA?.isLoading;


