import * as login from './loginAction'
import * as store from './storeAction'
import * as edge from './edgeAction'
import * as camera from './cameraAction'
import * as category from './categoryAction'
import * as adminProduct from './adminProductAction'
import * as kiosk from './kioskAction'
import * as adminOnboard from './adminOnboardAction'
import * as vendorProduct from './vendorProductAction'
import * as notification from './notificationAction'
import * as dashboard from './dashboardAction'

export const Actions = {
    ...login,
    ...store,
    ...edge,
    ...camera,
    ...category,
    ...adminProduct,
    ...kiosk,
    ...adminOnboard,
    ...vendorProduct,
    ...notification,
    ...dashboard
}
export const doAction = (type, params = {}, callback) => ({ type, params, callback });
export const doResponseAction = (type, data = {}) => ({ type, data });