import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_CATEGORY, UPDATE_CATEGORY,DELETE_CATEGORY,GET_CATEGORY,ACTIVE_CATEGORY } from '../action/categoryAction';

export function* getCategory({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_CATEGORY, params)
        yield put(Actions.getCategorySuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getCategoryFailure(error))
    }
}
export function* createCategory({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_CATEGORY, params)
        yield put(Actions.createCategorySuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createCategoryFailure(error))
    }
}
export function* updateCategory({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_CATEGORY, params)
        yield put(Actions.updateCategorySuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateCategoryFailure(error))
    }
}
export function* deleteCategory({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_CATEGORY, params)
        yield put(Actions.deleteCategorySuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteCategoryFailure(error))
    }
}
export function* activeCategory({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_CATEGORY, params)
        yield put(Actions.activeCategorySuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeCategoryFailure(error))
    }
}
export default function* categoryWatcher() {
    yield all([
        takeLatest(GET_CATEGORY, getCategory),
        takeLatest(CREATE_CATEGORY, createCategory),
        takeLatest(UPDATE_CATEGORY, updateCategory),
        takeLatest(DELETE_CATEGORY, deleteCategory),
        takeLatest(ACTIVE_CATEGORY, activeCategory),

    ]);
}
