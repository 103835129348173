import { doAction, doResponseAction } from ".";

const GET_ADMIN_ON_BOARD = 'GET_ADMIN_ON_BOARD';
const GET_ADMIN_ON_BOARD_SUCCESS = 'GET_ADMIN_ON_BOARD_SUCCESS';
const GET_ADMIN_ON_BOARD_FAILURE = 'GET_ADMIN_ON_BOARD_FAILURE';


const CREATE_ADMIN_ON_BOARD = 'CREATE_ADMIN_ON_BOARD';
const CREATE_ADMIN_ON_BOARD_SUCCESS = 'CREATE_ADMIN_ON_BOARD_SUCCESS';
const CREATE_ADMIN_ON_BOARD_FAILURE = 'CREATE_ADMIN_ON_BOARD_FAILURE';

const UPDATE_ADMIN_ON_BOARD = 'UPDATE_ADMIN_ON_BOARD';
const UPDATE_ADMIN_ON_BOARD_SUCCESS = 'UPDATE_ADMIN_ON_BOARD_SUCCESS';
const UPDATE_ADMIN_ON_BOARD_FAILURE = 'UPDATE_ADMIN_ON_BOARD_FAILURE';

const DELETE_ADMIN_ON_BOARD = 'DELETE_ADMIN_ON_BOARD';
const DELETE_ADMIN_ON_BOARD_SUCCESS = 'DELETE_ADMIN_ON_BOARD_SUCCESS';
const DELETE_ADMIN_ON_BOARD_FAILURE = 'DELETE_ADMIN_ON_BOARD_FAILURE';

const ACTIVE_ADMIN_ON_BOARD = 'ACTIVE_ADMIN_ON_BOARD';
const ACTIVE_ADMIN_ON_BOARD_SUCCESS = 'ACTIVE_ADMIN_ON_BOARD_SUCCESS';
const ACTIVE_ADMIN_ON_BOARD_FAILURE = 'ACTIVE_ADMIN_ON_BOARD_FAILURE';

export {
    GET_ADMIN_ON_BOARD,
    GET_ADMIN_ON_BOARD_SUCCESS,
    GET_ADMIN_ON_BOARD_FAILURE,
    
    CREATE_ADMIN_ON_BOARD,
    CREATE_ADMIN_ON_BOARD_SUCCESS,
    CREATE_ADMIN_ON_BOARD_FAILURE,

    UPDATE_ADMIN_ON_BOARD,
    UPDATE_ADMIN_ON_BOARD_SUCCESS,
    UPDATE_ADMIN_ON_BOARD_FAILURE,

    DELETE_ADMIN_ON_BOARD,
    DELETE_ADMIN_ON_BOARD_SUCCESS,
    DELETE_ADMIN_ON_BOARD_FAILURE,

    ACTIVE_ADMIN_ON_BOARD,
    ACTIVE_ADMIN_ON_BOARD_SUCCESS,
    ACTIVE_ADMIN_ON_BOARD_FAILURE,
}

export const getAdminOnboard = (params, callback) => doAction(GET_ADMIN_ON_BOARD, params, callback);
export const getAdminOnboardSuccess = (data) => doResponseAction(GET_ADMIN_ON_BOARD_SUCCESS, data);
export const getAdminOnboardFailure = (data) => doResponseAction(GET_ADMIN_ON_BOARD_FAILURE, data);

export const createAdminOnboard = (params, callback) => doAction(CREATE_ADMIN_ON_BOARD, params, callback);
export const createAdminOnboardSuccess = (data) => doResponseAction(CREATE_ADMIN_ON_BOARD_SUCCESS, data);
export const createAdminOnboardFailure = (data) => doResponseAction(CREATE_ADMIN_ON_BOARD_FAILURE, data);

export const updateAdminOnboard = (params, callback) => doAction(UPDATE_ADMIN_ON_BOARD, params, callback);
export const updateAdminOnboardSuccess = (data) => doResponseAction(UPDATE_ADMIN_ON_BOARD_SUCCESS, data);
export const updateAdminOnboardFailure = (data) => doResponseAction(UPDATE_ADMIN_ON_BOARD_FAILURE, data);

export const deleteAdminOnboard = (params, callback) => doAction(DELETE_ADMIN_ON_BOARD, params, callback);
export const deleteAdminOnboardSuccess = (data) => doResponseAction(DELETE_ADMIN_ON_BOARD_SUCCESS, data);
export const deleteAdminOnboardFailure = (data) => doResponseAction(DELETE_ADMIN_ON_BOARD_FAILURE, data);

export const activeAdminOnboard = (params, callback) => doAction(ACTIVE_ADMIN_ON_BOARD, params, callback);
export const activeAdminOnboardSuccess = (data) => doResponseAction(ACTIVE_ADMIN_ON_BOARD_SUCCESS, data);
export const activeAdminOnboardFailure = (data) => doResponseAction(ACTIVE_ADMIN_ON_BOARD_FAILURE, data);