import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { storeReducers } from './storeReducer';
import { edgeReducers } from './edgeReducer';
import { cameraReducers } from './cameraReducer';
import { categoryReducers } from './categoryReducer';
import { adminProductReducers } from './adminProductReducer';
import { kioskReducers } from './kioskReducer';
import { adminOnboardReducers } from './adminOnboardReducer';
import { vendorProductReducers } from './vendorProductReducer';
import { notificationReducers } from './notificationReducer';
import { dashboardReducers } from './dashboardReducer';
const rootReducer = combineReducers({
  USER: loginReducer,
  STORE: storeReducers,
  EDGE: edgeReducers,
  CAMERA: cameraReducers,
  CATEGORY: categoryReducers,
  ADMIN_PRODUCT: adminProductReducers,
  VENDOR_PRODUCT :vendorProductReducers,
  KIOSK: kioskReducers,
  ADMIN_ON_BOARD:adminOnboardReducers,
  NOTIFICATION:notificationReducers,
  DASHBOARD:dashboardReducers
});
export default rootReducer;