import { doAction, doResponseAction } from ".";

const GET_KIOSK = 'GET_KIOSK';
const GET_KIOSK_SUCCESS = 'GET_KIOSK_SUCCESS';
const GET_KIOSK_FAILURE = 'GET_KIOSK_FAILURE';


const CREATE_KIOSK = 'CREATE_KIOSK';
const CREATE_KIOSK_SUCCESS = 'CREATE_KIOSK_SUCCESS';
const CREATE_KIOSK_FAILURE = 'CREATE_KIOSK_FAILURE';

const UPDATE_KIOSK = 'UPDATE_KIOSK';
const UPDATE_KIOSK_SUCCESS = 'UPDATE_KIOSK_SUCCESS';
const UPDATE_KIOSK_FAILURE = 'UPDATE_KIOSK_FAILURE';

const DELETE_KIOSK = 'DELETE_KIOSK';
const DELETE_KIOSK_SUCCESS = 'DELETE_KIOSK_SUCCESS';
const DELETE_KIOSK_FAILURE = 'DELETE_KIOSK_FAILURE';

const ACTIVE_KIOSK = 'ACTIVE_KIOSK';
const ACTIVE_KIOSK_SUCCESS = 'ACTIVE_KIOSK_SUCCESS';
const ACTIVE_KIOSK_FAILURE = 'ACTIVE_KIOSK_FAILURE';


export {
    GET_KIOSK,
    GET_KIOSK_SUCCESS,
    GET_KIOSK_FAILURE,
    
    CREATE_KIOSK,
    CREATE_KIOSK_SUCCESS,
    CREATE_KIOSK_FAILURE,

    UPDATE_KIOSK,
    UPDATE_KIOSK_SUCCESS,
    UPDATE_KIOSK_FAILURE,

    DELETE_KIOSK,
    DELETE_KIOSK_SUCCESS,
    DELETE_KIOSK_FAILURE,

    ACTIVE_KIOSK,
    ACTIVE_KIOSK_SUCCESS,
    ACTIVE_KIOSK_FAILURE,
}

export const getKiosk = (params, callback) => doAction(GET_KIOSK, params, callback);
export const getKioskSuccess = (data) => doResponseAction(GET_KIOSK_SUCCESS, data);
export const getKioskFailure = (data) => doResponseAction(GET_KIOSK_FAILURE, data);

export const createKiosk = (params, callback) => doAction(CREATE_KIOSK, params, callback);
export const createKioskSuccess = (data) => doResponseAction(CREATE_KIOSK_SUCCESS, data);
export const createKioskFailure = (data) => doResponseAction(CREATE_KIOSK_FAILURE, data);

export const updateKiosk = (params, callback) => doAction(UPDATE_KIOSK, params, callback);
export const updateKioskSuccess = (data) => doResponseAction(UPDATE_KIOSK_SUCCESS, data);
export const updateKioskFailure = (data) => doResponseAction(UPDATE_KIOSK_FAILURE, data);

export const deleteKiosk = (params, callback) => doAction(DELETE_KIOSK, params, callback);
export const deleteKioskSuccess = (data) => doResponseAction(DELETE_KIOSK_SUCCESS, data);
export const deleteKioskFailure = (data) => doResponseAction(DELETE_KIOSK_FAILURE, data);

export const activeKiosk = (params, callback) => doAction(ACTIVE_KIOSK, params, callback);
export const activeKioskSuccess = (data) => doResponseAction(ACTIVE_KIOSK_SUCCESS, data);
export const activeKioskFailure = (data) => doResponseAction(ACTIVE_KIOSK_FAILURE, data);