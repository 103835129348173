export const DeclinePopup = ({ title, onClose, onSuccess }) => {
    return (
        <>
            <div className="modal d-block">
                <div className="modal-dialog model-top">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Alert</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-12">
                                    <label for="oldPassword" className="form-label">
                                        {title}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={onSuccess}
                                className="btn save_btn"
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                onClick={onClose}
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}