import { Actions } from '../action';

const initialState = {
    isLoading: false,
    adminProductList: [],
    adminActiveProductList:[],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const adminProductReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_ADMIN_PRODUCT: {
            return commonState(state);
        }
        case Actions.GET_ADMIN_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                adminProductList: action?.data?.result,
                totalCount:action.data?.count,
                error: false
            };
        }
        case Actions.GET_ADMIN_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_ADMIN_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_ADMIN_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_ADMIN_PRODUCT: {
            return commonState(state);
        }
        case Actions.UPDATE_ADMIN_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_ADMIN_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_ADMIN_PRODUCT: {
            return commonState(state);
        }
        case Actions.DELETE_ADMIN_PRODUCT_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_ADMIN_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_ADMIN_PRODUCT: {
            return commonState(state);
        }
        case Actions.ACTIVE_ADMIN_PRODUCT_SUCCESS: {
            return {
                ...state,
                adminActiveProductList: action?.data,
                isLoading: false,
                error: false
            };
        }
        case Actions.ACTIVE_ADMIN_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_ADMIN_PRODUCT: {
            return commonState(state);
        }
        default:
            return state;
    }
};



export const adminProductList = (state) => state?.ADMIN_PRODUCT?.adminProductList;
export const adminActiveProductList = (state) => state?.ADMIN_PRODUCT?.adminActiveProductList;
export const totalItemCount = (state) => state?.ADMIN_PRODUCT?.totalCount;
export const adminProductListLoadingSelector = state => state?.ADMIN_PRODUCT?.isLoading;


