import { doAction, doResponseAction } from ".";
const GET_JOURNEY = 'GET_JOURNEY';
const GET_JOURNEY_SUCCESS = 'GET_JOURNEY_SUCCESS';
const GET_JOURNEY_FAILURE = 'GET_JOURNEY_FAILURE';

const GET_THEFT_ANALYSIS = 'GET_THEFT_ANALYSIS';
const GET_THEFT_ANALYSIS_SUCCESS = 'GET_THEFT_ANALYSIS_SUCCESS';
const GET_THEFT_ANALYSIS_FAILURE = 'GET_THEFT_ANALYSIS_FAILURE';

const GET_HIGH_VALUE_PRODUCT = 'GET_HIGH_VALUE_PRODUCT';
const GET_HIGH_VALUE_PRODUCT_SUCCESS = 'GET_HIGH_VALUE_PRODUCT_SUCCESS';
const GET_HIGH_VALUE_PRODUCT_FAILURE = 'GET_HIGH_VALUE_PRODUCT_FAILURE';

const GET_HIGH_VOLUME_PRODUCT = 'GET_HIGH_VOLUME_PRODUCT';
const GET_HIGH_VOLUME_PRODUCT_SUCCESS = 'GET_HIGH_VOLUME_PRODUCT_SUCCESS';
const GET_HIGH_VOLUME_PRODUCT_FAILURE = 'GET_HIGH_VOLUME_PRODUCT_FAILURE';

const GET_TOTAL_SAVED_PRODUCT = 'GET_TOTAL_SAVED_PRODUCT';
const GET_TOTAL_SAVED_PRODUCT_SUCCESS = 'GET_TOTAL_SAVED_PRODUCT_SUCCESS';
const GET_TOTAL_SAVED_PRODUCT_FAILURE = 'GET_TOTAL_SAVED_PRODUCT_FAILURE';

const GET_TOTAL_SAVED_PRICE = 'GET_TOTAL_SAVED_PRICE';
const GET_TOTAL_SAVED_PRICE_SUCCESS = 'GET_TOTAL_SAVED_PRICE_SUCCESS';
const GET_TOTAL_SAVED_PRICE_FAILURE = 'GET_TOTAL_SAVED_PRICE_FAILURE';

const GET_TIME_ANALYSIS = 'GET_TIME_ANALYSIS';
const GET_TIME_ANALYSIS_SUCCESS = 'GET_TIME_ANALYSIS_SUCCESS';
const GET_TIME_ANALYSIS_FAILURE = 'GET_TIME_ANALYSIS_FAILURE';

const GET_PREVENTED_PRODUCT_ANALYTIC = 'GET_PREVENTED_PRODUCT_ANALYTIC';
const GET_PREVENTED_PRODUCT_ANALYTIC_SUCCESS = 'GET_PREVENTED_PRODUCT_ANALYTIC_SUCCESS';
const GET_PREVENTED_PRODUCT_ANALYTIC_FAILURE = 'GET_PREVENTED_PRODUCT_ANALYTIC_FAILURE';

export {
    GET_JOURNEY,
    GET_JOURNEY_SUCCESS,
    GET_JOURNEY_FAILURE,
    
    GET_THEFT_ANALYSIS,
    GET_THEFT_ANALYSIS_SUCCESS,
    GET_THEFT_ANALYSIS_FAILURE,

    GET_HIGH_VALUE_PRODUCT,
    GET_HIGH_VALUE_PRODUCT_SUCCESS,
    GET_HIGH_VALUE_PRODUCT_FAILURE,

    GET_HIGH_VOLUME_PRODUCT,
    GET_HIGH_VOLUME_PRODUCT_SUCCESS,
    GET_HIGH_VOLUME_PRODUCT_FAILURE,

    GET_TOTAL_SAVED_PRODUCT,
    GET_TOTAL_SAVED_PRODUCT_SUCCESS,
    GET_TOTAL_SAVED_PRODUCT_FAILURE,

    GET_TOTAL_SAVED_PRICE,
    GET_TOTAL_SAVED_PRICE_SUCCESS,
    GET_TOTAL_SAVED_PRICE_FAILURE,

    GET_TIME_ANALYSIS,
    GET_TIME_ANALYSIS_SUCCESS,
    GET_TIME_ANALYSIS_FAILURE,

    GET_PREVENTED_PRODUCT_ANALYTIC,
    GET_PREVENTED_PRODUCT_ANALYTIC_SUCCESS,
    GET_PREVENTED_PRODUCT_ANALYTIC_FAILURE,
}

export const getJourney = (params, callback) => doAction(GET_JOURNEY, params, callback);
export const getJourneySuccess = (data) => doResponseAction(GET_JOURNEY_SUCCESS, data);
export const getJourneyFailure = (data) => doResponseAction(GET_JOURNEY_FAILURE, data);


export const getTheftAnalysis = (params, callback) => doAction(GET_THEFT_ANALYSIS, params, callback);
export const getTheftAnalysisSuccess = (data) => doResponseAction(GET_THEFT_ANALYSIS_SUCCESS, data);
export const getTheftAnalysisFailure = (data) => doResponseAction(GET_THEFT_ANALYSIS_FAILURE, data);

export const getHighValueProduct = (params, callback) => doAction(GET_HIGH_VALUE_PRODUCT, params, callback);
export const getHighValueProductSuccess = (data) => doResponseAction(GET_HIGH_VALUE_PRODUCT_SUCCESS, data);
export const getHighValueProductFailure = (data) => doResponseAction(GET_HIGH_VALUE_PRODUCT_FAILURE, data);

export const getHighVolumeProduct = (params, callback) => doAction(GET_HIGH_VOLUME_PRODUCT, params, callback);
export const getHighVolumeProductSuccess = (data) => doResponseAction(GET_HIGH_VOLUME_PRODUCT_SUCCESS, data);
export const getHighVolumeProductFailure = (data) => doResponseAction(GET_HIGH_VOLUME_PRODUCT_FAILURE, data);

export const getTotalSavedProduct = (params, callback) => doAction(GET_TOTAL_SAVED_PRODUCT, params, callback);
export const getTotalSavedProductSuccess = (data) => doResponseAction(GET_TOTAL_SAVED_PRODUCT_SUCCESS, data);
export const getTotalSavedProductFailure = (data) => doResponseAction(GET_TOTAL_SAVED_PRODUCT_FAILURE, data);

export const getTotalSavedPrice = (params, callback) => doAction(GET_TOTAL_SAVED_PRICE, params, callback);
export const getTotalSavedPriceSuccess = (data) => doResponseAction(GET_TOTAL_SAVED_PRICE_SUCCESS, data);
export const getTotalSavedPriceFailure = (data) => doResponseAction(GET_TOTAL_SAVED_PRICE_FAILURE, data);

export const getTimeAnalysisProduct = (params, callback) => doAction(GET_TIME_ANALYSIS, params, callback);
export const getTimeAnalysisProductSuccess = (data) => doResponseAction(GET_TIME_ANALYSIS_SUCCESS, data);
export const getTimeAnalysisProductFailure = (data) => doResponseAction(GET_TIME_ANALYSIS_FAILURE, data);

export const getPreventedProductAnalytic = (params, callback) => doAction(GET_PREVENTED_PRODUCT_ANALYTIC, params, callback);
export const getPreventedProductAnalyticSuccess = (data) => doResponseAction(GET_PREVENTED_PRODUCT_ANALYTIC_SUCCESS, data);
export const getPreventedProductAnalyticFailure = (data) => doResponseAction(GET_PREVENTED_PRODUCT_ANALYTIC_FAILURE, data);
