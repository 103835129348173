import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_STORE, UPDATE_STORE,DELETE_STORE,GET_STORE,ACTIVE_STORE } from '../action/storeAction';

export function* getStore({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_STORE, params)
        yield put(Actions.getStoreSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getStoreFailure(error))
    }
}
export function* createStore({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_STORE, params)
        yield put(Actions.createStoreSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createStoreFailure(error))
    }
}
export function* updateStore({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_STORE, params)
        yield put(Actions.updateStoreSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateStoreFailure(error))
    }
}
export function* deleteStore({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_STORE, params)
        yield put(Actions.deleteStoreSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteStoreFailure(error))
    }
}

export function* activeStore({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_STORE, params)
        yield put(Actions.activeStoreSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeStoreFailure(error))
    }
}
export default function* storeWatcher() {
    yield all([
        takeLatest(GET_STORE, getStore),
        takeLatest(CREATE_STORE, createStore),
        takeLatest(UPDATE_STORE, updateStore),
        takeLatest(DELETE_STORE, deleteStore),
        takeLatest(ACTIVE_STORE, activeStore),

    ]);
}
