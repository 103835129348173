import { doAction, doResponseAction } from ".";

const GET_CATEGORY = 'GET_CATEGORY';
const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';


const CREATE_CATEGORY = 'CREATE_CATEGORY';
const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

const DELETE_CATEGORY = 'DELETE_CATEGORY';
const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

const ACTIVE_CATEGORY = 'ACTIVE_CATEGORY';
const ACTIVE_CATEGORY_SUCCESS = 'ACTIVE_CATEGORY_SUCCESS';
const ACTIVE_CATEGORY_FAILURE = 'ACTIVE_CATEGORY_FAILURE';

export {
    GET_CATEGORY,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
    
    CREATE_CATEGORY,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,

    UPDATE_CATEGORY,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE,

    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,

    ACTIVE_CATEGORY,
    ACTIVE_CATEGORY_SUCCESS,
    ACTIVE_CATEGORY_FAILURE,
}

export const getCategory = (params, callback) => doAction(GET_CATEGORY, params, callback);
export const getCategorySuccess = (data) => doResponseAction(GET_CATEGORY_SUCCESS, data);
export const getCategoryFailure = (data) => doResponseAction(GET_CATEGORY_FAILURE, data);

export const createCategory = (params, callback) => doAction(CREATE_CATEGORY, params, callback);
export const createCategorySuccess = (data) => doResponseAction(CREATE_CATEGORY_SUCCESS, data);
export const createCategoryFailure = (data) => doResponseAction(CREATE_CATEGORY_FAILURE, data);

export const updateCategory = (params, callback) => doAction(UPDATE_CATEGORY, params, callback);
export const updateCategorySuccess = (data) => doResponseAction(UPDATE_CATEGORY_SUCCESS, data);
export const updateCategoryFailure = (data) => doResponseAction(UPDATE_CATEGORY_FAILURE, data);

export const deleteCategory = (params, callback) => doAction(DELETE_CATEGORY, params, callback);
export const deleteCategorySuccess = (data) => doResponseAction(DELETE_CATEGORY_SUCCESS, data);
export const deleteCategoryFailure = (data) => doResponseAction(DELETE_CATEGORY_FAILURE, data);

export const activeCategory = (params, callback) => doAction(ACTIVE_CATEGORY, params, callback);
export const activeCategorySuccess = (data) => doResponseAction(ACTIVE_CATEGORY_SUCCESS, data);
export const activeCategoryFailure = (data) => doResponseAction(ACTIVE_CATEGORY_FAILURE, data);
