import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { totalSavedProductItem, totalSavedPriceItem } from '../redux/reducer/dashboardReducer';
import { useSelector } from 'react-redux';
import { MonthLabels, WeekLabels, dayLabels, filterByMonth, filterByTime, filterByWeek, filterByday, filterBydayWithLastWeek, lastWeekDay, timeLabelIn12 } from './SavedAnalysis';

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};

const Area = ({ selectedBottom, filter }) => {
    const totalSavedProduct = useSelector(totalSavedProductItem)
    const totalSavedPrice = useSelector(totalSavedPriceItem)
    let graphDataInCount;
    let xlabelsInCount;
    let graphDataInPrice;
    let xlabelsInPrice;
    switch (filter) {
        case "current_day":
        case "previous_day":
            graphDataInCount = filterByTime(totalSavedProduct);
            xlabelsInCount = timeLabelIn12
            break;
        case "current_week":
        case "previous_week":
            graphDataInCount = filterByday(totalSavedProduct);
            xlabelsInCount = dayLabels
            break;
        case "current_month":
        case "previous_month":
            graphDataInCount = filterByWeek(totalSavedProduct);
            xlabelsInCount = WeekLabels
            break;
        case "current_year":
        case "previous_year":
            graphDataInCount = filterByMonth(totalSavedProduct);
            xlabelsInCount = MonthLabels
            break;
        default:
            graphDataInCount = filterBydayWithLastWeek(totalSavedProduct);
            xlabelsInCount = lastWeekDay
            break;
    }
    switch (filter) {
        case "current_day":
        case "previous_day":
            graphDataInPrice = filterByTime(totalSavedPrice);
            xlabelsInPrice = timeLabelIn12
            break;
        case "current_week":
        case "previous_week":
            graphDataInPrice = filterByday(totalSavedPrice);
            xlabelsInPrice = dayLabels
            break;
        case "current_month":
        case "previous_month":
            graphDataInPrice = filterByWeek(totalSavedPrice);
            xlabelsInPrice = WeekLabels
            break;
        case "current_year":
        case "previous_year":
            graphDataInPrice = filterByMonth(totalSavedPrice);
            xlabelsInPrice = MonthLabels
            break;
        default:
            graphDataInPrice = filterBydayWithLastWeek(totalSavedPrice);
            xlabelsInPrice = lastWeekDay
            break;
    }
    const totalSavedProductLable = xlabelsInCount
    const totalSavedProductData = graphDataInCount
    const totalSavedPriceLable = xlabelsInPrice
    const totalSavedPriceData = graphDataInPrice
    const data = {
        labels: selectedBottom === 'Saved Product' ? totalSavedProductLable : totalSavedPriceLable,
        datasets: [
            {
                fill: true,
                label: selectedBottom === 'Saved Product' ? "Product Count" : "Product Price",
                data: selectedBottom === 'Saved Product' ? totalSavedProductData?.map((obj) => obj?.saved_count) : totalSavedPriceData?.map((obj) => obj?.saved_count),
                borderColor: 'rgb(53, 162, 235)', // Blue border color
                backgroundColor: 'rgba(53, 162, 235, 0.5)', // Semi-transparent blue background color
                cubicInterpolationMode: 'linear'
            },
        ],
    };
    return <Line options={options} data={data} />;
}

export default Area;
