import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { theftAnalysis } from '../redux/reducer/dashboardReducer';
import { useSelector } from 'react-redux';

const PieChart = ({ filter }) => {
    const theftList = useSelector(theftAnalysis)
    const data = {
        labels: ['Aisle Suspicious Count', 'Checkout Suspicious Count', 'Traditional Suspicious Count'],
        datasets: [
            {
                data: [
                    theftList?.aisle_suspicious_count == null ? 0 : theftList?.aisle_suspicious_count,
                    theftList?.checkout_suspicious_count == null ? 0 : theftList?.checkout_suspicious_count,
                    theftList?.traditional_suspicious_count == null ? 0 : theftList?.traditional_suspicious_count
                ], // Data as percentages
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const options = {
        plugins: {
            datalabels: {
                color: '#fff', // Color of the percentage labels
                formatter: (value, ctx) => {
                    const dataset = ctx.chart.data.datasets[0];
                    const total = dataset?.data?.reduce((accumulator, currentValue) => accumulator + currentValue);
                    const currentValue = dataset.data[ctx.dataIndex];
                    if (currentValue === 0) {
                        return '0%'; // Handle the case where the segment value is 0
                    } else {
                        const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                        return percentage;
                    }
                },
            },
        },
    };


    return <Pie data={data} options={options} plugins={[ChartDataLabels]} />
};
export default PieChart;