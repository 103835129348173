import React from 'react';
import { Bar } from 'react-chartjs-2';
import { timeAnalysisData } from '../redux/reducer/dashboardReducer';
import { useSelector } from 'react-redux';

const VerticalBarChart = ({ selectedTimeChart, filter }) => {
  const labels = ['Morning(6-12)', 'Evening(12-6)', 'Night(6-12)'];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: 'Time Analysis',
      },
    },
  };

  const timeAnalysisList = useSelector(timeAnalysisData)
  let timeEvgData = timeAnalysisList?.evening == null ? 0 : timeAnalysisList?.evening
  let timeMorData = timeAnalysisList?.morning == null ? 0 : timeAnalysisList?.morning
  let timeNigData = timeAnalysisList?.night == null ? 0 : timeAnalysisList?.night

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Time Analysis',
        data: [timeEvgData, timeMorData, timeNigData],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',  // Background color for the first bar
          'rgba(25, 99, 132, 0.5)',   // Background color for the second bar
          'rgba(53, 22, 32, 0.5)',    // Background color for the third bar
        ],
        borderColor: [
          'rgb(255, 99, 132)',  // Border color for the first bar
          'rgb(25, 99, 132)',   // Border color for the second bar
          'rgb(53, 22, 32)',    // Border color for the third bar
        ],
      },
    ],
  };
  return <Bar options={options} data={data} />

}

export default VerticalBarChart;
