import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { cameraDetailsValidateField } from "../utils/Validation";
import { cameraList, totalItemCount } from '../redux/reducer/cameraReducer';
import { toast } from "react-toastify";
import { PaginationComponent } from "../component/Pagination";
import AppLoader from "../component/AppLoader";
import { DeclinePopup } from "../component/DeclinePopup";
import EdgeTypeAhead from "../component/EdgeTypeAhead";
import { edgeActiveList } from "../redux/reducer/edgeReducer";
import StoreTypeAhead from "../component/StoreTypeAhead";
import { getLoginData } from "../redux/reducer/loginReducer";
import MESSAGE from "../constant/String";


const Camera = () => {
    const dispatch = useDispatch()
    const userData = useSelector(getLoginData)
    const cameraListData = useSelector(cameraList)
    const activeEdgeList = useSelector(edgeActiveList)
    const totalCount = useSelector(totalItemCount)
    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [showAddList, setShowAddList] = useState(false);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [selectedEdge, setSelectedEdge] = useState([]);
    const [selectedStore, setSelectedStore] = useState([]);
    const [selectSearch, setSelectedSearch] = useState()
    const [cameraDetails, setCameraDetails] = useState({
        name: "",
        address: "",
        cv_edge_id: "",
    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        address: false,
        cv_edge_id: false,
    });


    const getCameraList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            store_id: selectedStore?.value,
            edge_id: selectedEdge?.value,
            search: selectSearch
        }
        setLoading(true)
        dispatch(Actions.getCamera(params, callback))
    }, [dispatch, pageNumber, selectedEdge?.value, selectedStore?.value, selectSearch])

    useEffect(() => {
        getCameraList()
    }, [dispatch, getCameraList, userData?.role_id])

    const handleFieldChange = (field, value) => {
        setCameraDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleAddButtonClick = () => {
        setShowAddList(true);
        setCameraDetails({
            name: "",
            address: "",
            cv_edge_id: "",
        })
    };
    const handleBackButtonClick = () => {
        setShowAddList(false);
        setCameraDetails({
            name: "",
            address: "",
            cv_edge_id: "",
        })
        setFieldChangedStatus(() => ({
            name: false,
            address: false,
            cv_edge_id: false,
        }));
    };
    const onEdit = (obj) => {
        const { name, address, cv_edge, id } = obj;
        const selectedEdgeItem = activeEdgeList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === cv_edge);
        setCameraDetails({
            id: id,
            name: name,
            address: address,
            cv_edge_id: selectedEdgeItem,
        })
        setShowAddList(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};
        let hasErrors = false;
        for (const field in cameraDetails) {
            if (cameraDetailsValidateField(field, cameraDetails?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);

        if (!hasErrors) {
            setLoading(true)
            const callback = (response) => {
                setLoading(false)
                if (response?.response_code === 0) {
                    handleBackButtonClick()
                    getCameraList();
                    toast.success(response?.response_message)
                } else {
                    toast.error(response?.response_message)
                }
            };
            let params = {
                id: cameraDetails?.id,
                name: cameraDetails?.name,
                address: cameraDetails?.address,
                cv_edge_id: cameraDetails?.cv_edge_id?.value
            }
            if (cameraDetails?.id) {
                dispatch(Actions.updateCamera(params, callback))
            } else {
                delete params.id;
                dispatch(Actions.createCamera(params, callback))
            }

        }
    };
    const activeCamera = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick();
                getCameraList();
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "camera_id": itemObj?.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteCamera(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (obj) => {
        setItemObj(obj);
        setOpenDeclineModal(true);
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const handleEdgeSelect = (selected) => {
        setCameraDetails({ ...cameraDetails, 'cv_edge_id': selected })
    };
    const onStoreChange = (selected) => {
        setPageNumber(1)
        setSelectedStore(selected)
        setSelectedEdge([])
    };
    const onEdgeChange = (selected) => {
        setSelectedEdge(selected?.value ? selected : [])
    };
    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard">
                {isLoading && <AppLoader />}
                {!showAddList && <div className="vision_dashboard_content">
                    <div className="right_side right_side_mid right_side_cover ">
                        <div className="body_content">
                            <div className="body_header">
                                <p className="m-0 title">Camera</p>
                                <div className="d-flex gap-2">
                                    <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(false)}>Camera list</p>
                                </div>
                            </div>

                            {/* <!-- add button start --> */}
                            <div className="d-md-flex justify-content-between my-4">
                                <div className="d-md-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search camera name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                    {userData?.role_id !== 3 && <StoreTypeAhead
                                        className="dropdown_wrap"
                                        onChange={onStoreChange}
                                        id="StoreTypeAhead"
                                        style={{ margin: '10px' }}
                                        placeholder="Select the store"
                                        isMulti={false}
                                        disabled
                                        defaultValue={selectedStore}
                                    />}
                                    {(selectedStore?.value || userData?.role_id === 3) && <EdgeTypeAhead
                                        className="dropdown_wrap"
                                        id="edgeTypeAhead"
                                        style={{ margin: '10px' }}
                                        placeholder="Select the edge"
                                        isMulti={false}
                                        onChange={onEdgeChange}
                                        selectedStoreOption={selectedStore}
                                        defaultValue={selectedEdge}
                                        value={(selectedStore?.value || selectedEdge) ? selectedEdge : []}
                                        disabled
                                    />}
                                </div>

                                <button className="add_btn" onClick={(handleAddButtonClick)}><p className="m-0">Add</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                </button>
                            </div>
                            {/* <!-- add button end --> */}
                            {/* <!-- table start --> */}
                            <div className="table_bg">
                                <div className="table_body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Camera Name</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(cameraListData) && cameraListData?.map((obj, index) => (
                                                <tr key={obj?.id}>
                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                    <td>{obj?.name}</td>
                                                    <td>{obj?.address}</td>
                                                    <td>{obj?.is_active ? <p className="m-0 status_td">Active</p> : <p className="m-0 inactive_td">In Active</p>}</td>
                                                    <td>
                                                        <div className="action_td">
                                                            <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    strokeWidth="1.5" stroke="#1a9f53" width="16" height="16" >
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                                </svg>
                                                            </button>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckChecked"
                                                                    checked={obj?.is_active}
                                                                    onClick={(e) => handleClick(obj)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {Array.isArray(cameraListData) && cameraListData?.length == 0 && (
                                                <tr className="tr-shadow">
                                                    <td colSpan="6" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {Array.isArray(cameraListData) && cameraListData?.length > 0 &&
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                    </div>}
                            </div>
                            {/* <!-- table end --> */}
                        </div>
                    </div>
                </div>}
                {showAddList && <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Camera</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 pointer-hover" onClick={() => handleBackButtonClick()}>Camera list</p>
                                        <p className="m-0">~</p>
                                        <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(true)}> {cameraDetails?.id ? "Update Camera" : "Create Camera"}</p>
                                    </div>
                                </div>
                                {/* <!-- Navigation Button end --> */}
                                {/* <!-- Camera Info Start --> */}
                                <div className="list_form mt-4">
                                    <div className="d-flex justify-content-between align-items-center"><p className="m-0 table_heading">Camera Information</p>
                                        <button className="add_btn" onClick={() => handleBackButtonClick()}><p className="m-0">Back</p></button>
                                    </div>
                                    <div className="row g-3 mt-3">
                                        <div className="col-md-6">
                                            <label className="form-label">Edge</label>
                                            <EdgeTypeAhead
                                                id="edgeTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Edge"
                                                isMulti={false}
                                                defaultValue={cameraDetails?.cv_edge_id}
                                                onChange={handleEdgeSelect}
                                                disabled
                                            />
                                            {fieldChangedStatus?.cv_edge_id && cameraDetailsValidateField('cv_edge_id', cameraDetails?.cv_edge_id) && (
                                                <span className="text-danger">{cameraDetailsValidateField('cv_edge_id', cameraDetails?.cv_edge_id)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Camera Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter camera name"
                                                value={cameraDetails?.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.name && cameraDetailsValidateField('name', cameraDetails?.name) && (
                                                <span className="text-danger">{cameraDetailsValidateField('name', cameraDetails?.name)}</span>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <label htmlFor="inputPassword4" className="form-label">Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Address"
                                                value={cameraDetails?.address}
                                                onChange={(e) => handleFieldChange('address', e?.target?.value)}
                                                maxLength={150}
                                            />
                                            {fieldChangedStatus?.address && cameraDetailsValidateField('address', cameraDetails?.address) && (
                                                <span className="text-danger">{cameraDetailsValidateField('address', cameraDetails?.address)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 col-4 mx-auto mt-4">
                                        <button onClick={handleSubmit} className="upload_btn" type="button">{cameraDetails?.id ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                {/* <!-- Camera Info end --> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => activeCamera()}
                />}
            </div>
        </React.Fragment>
    )
}

export default Camera