import React from "react"
import { useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import { getLoginData } from "../../redux/reducer/loginReducer";

const Sidebar = () => {
    const location = useLocation()
    const userData = useSelector(getLoginData)
    return (
        <React.Fragment>
            <div className="left_side left_side_show left_side_hide">
                <div className="side_header">
                    <img src={require('../../asset/img/white_cvi_logo.png')} alt="logo" width="160" />
                </div>
                <div className="side_content">
                    <ul className="nav flex-column mb-auto">
                        <p className="m-0 fs-17 sub_heading f-light">MENU</p>
                        <li className="mt-2">
                            <Link to={'/dashboard'} className={`nav-link link-dark ${location.pathname === '/dashboard' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Dashboard</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/adminProduct'} className={`nav-link link-dark ${location.pathname === '/adminProduct' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Admin Product</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/vendorProduct'} className={`nav-link link-dark ${location.pathname === '/vendorProduct' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Vendor Product</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/store'} className={`nav-link link-dark ${location.pathname === '/store' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Store</p>
                            </Link>
                        </li>
                        {userData?.role_id !== 3 && <li>
                            <Link to={'/adminOnboard'} className={`nav-link link-dark ${location.pathname === '/adminOnboard' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                                <p className="m-0 fs-17 ps-3">Admin Onboard</p>
                            </Link>
                        </li>}
                        <li>
                            <Link to={'/edge'} className={`nav-link link-dark ${location.pathname === '/edge' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Edge</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/camera'} className={`nav-link link-dark ${location.pathname === '/camera' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Camera</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/kiosk'} className={`nav-link link-dark ${location.pathname === '/kiosk' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Kiosk</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/notification'} className={`nav-link link-dark ${location.pathname === '/notification' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                                </svg>
                                <p className="m-0 fs-17 ps-3">Notification</p>
                            </Link>
                        </li>
                        {userData?.role_id === 1 && <li>
                            <Link to={'/category'} className={`nav-link link-dark ${location.pathname === '/category' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                                </svg>
                                <p className="m-0 fs-17 ps-3">Category</p>
                            </Link>
                        </li>}
                        {/* <li>
                            <Link to={''} className="nav-link link-dark d-flex alin-item-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">SOC Unit</p>
                            </Link>
                        </li> */}

                    </ul>
                </div>
            </div>
            {/* <!-- Offcanvas start --> */}

            <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                        <img src={require('../../asset/img/white_cvi_logo.png')} alt="logo" width="160" />
                    </h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav flex-column mb-auto">
                        <p className="m-0 fs-17 sub_heading f-light">MENU</p>
                        <li className="mt-2">
                            <Link to={'/dashboard'} className={`nav-link link-dark ${location.pathname === '/dashboard' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Dashboard</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/adminProduct'} className={`nav-link link-dark ${location.pathname === '/adminProduct' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Admin Product</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/vendorProduct'} className={`nav-link link-dark ${location.pathname === '/vendorProduct' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Vendor Product</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/store'} className={`nav-link link-dark ${location.pathname === '/store' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Store</p>
                            </Link>
                        </li>
                        {userData?.role_id !== 3 && <li>
                            <Link to={'/adminOnboard'} className={`nav-link link-dark ${location.pathname === '/adminOnboard' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                                <p className="m-0 fs-17 ps-3">Admin Onboard</p>
                            </Link>
                        </li>}
                        <li>
                            <Link to={'/edge'} className={`nav-link link-dark ${location.pathname === '/edge' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Edge</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/camera'} className={`nav-link link-dark ${location.pathname === '/camera' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Camera</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/kiosk'} className={`nav-link link-dark ${location.pathname === '/kiosk' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">Kiosk</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/notification'} className={`nav-link link-dark ${location.pathname === '/notification' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                                </svg>
                                <p className="m-0 fs-17 ps-3">Notification</p>
                            </Link>
                        </li>
                        {userData?.role_id === 1 && <li>
                            <Link to={'/category'} className={`nav-link link-dark ${location.pathname === '/category' ? 'active' : ''} d-flex align-items-center`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                                </svg>
                                <p className="m-0 fs-17 ps-3">Category</p>
                            </Link>
                        </li>}
                        {/* <li>
                            <Link to={''} className="nav-link link-dark d-flex alin-item-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                    stroke="currentColor" width="26" height="26">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                                </svg>

                                <p className="m-0 fs-17 ps-3">SOC Unit</p>
                            </Link>
                        </li> */}

                    </ul>
                </div>
            </div>
            {/* <!-- Offcanvas end --> */}
        </React.Fragment>
    )
}

export default Sidebar