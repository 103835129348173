import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_EDGE, UPDATE_EDGE,DELETE_EDGE,GET_EDGE,ACTIVE_EDGE } from '../action/edgeAction';

export function* getEdge({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_EDGE, params)
        yield put(Actions.getEdgeSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getEdgeFailure(error))
    }
}
export function* createEdge({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_EDGE, params)
        yield put(Actions.createEdgeSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createEdgeFailure(error))
    }
}
export function* updateEdge({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_EDGE, params)
        yield put(Actions.updateEdgeSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateEdgeFailure(error))
    }
}
export function* deleteEdge({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_EDGE, params)
        yield put(Actions.deleteEdgeSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.deleteEdgeFailure(error))
    }
}

export function* activeEdge({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_EDGE, params)
        yield put(Actions.activeEdgeSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeEdgeFailure(error))
    }
}

export default function* edgeWatcher() {
    yield all([
        takeLatest(GET_EDGE, getEdge),
        takeLatest(CREATE_EDGE, createEdge),
        takeLatest(UPDATE_EDGE, updateEdge),
        takeLatest(DELETE_EDGE, deleteEdge),
        takeLatest(ACTIVE_EDGE, activeEdge),

    ]);
}
