import { doAction, doResponseAction } from ".";

const GET_ADMIN_PRODUCT = 'GET_ADMIN_PRODUCT';
const GET_ADMIN_PRODUCT_SUCCESS = 'GET_ADMIN_PRODUCT_SUCCESS';
const GET_ADMIN_PRODUCT_FAILURE = 'GET_ADMIN_PRODUCT_FAILURE';


const CREATE_ADMIN_PRODUCT = 'CREATE_ADMIN_PRODUCT';
const CREATE_ADMIN_PRODUCT_SUCCESS = 'CREATE_ADMIN_PRODUCT_SUCCESS';
const CREATE_ADMIN_PRODUCT_FAILURE = 'CREATE_ADMIN_PRODUCT_FAILURE';

const UPDATE_ADMIN_PRODUCT = 'UPDATE_ADMIN_PRODUCT';
const UPDATE_ADMIN_PRODUCT_SUCCESS = 'UPDATE_ADMIN_PRODUCT_SUCCESS';
const UPDATE_ADMIN_PRODUCT_FAILURE = 'UPDATE_ADMIN_PRODUCT_FAILURE';

const DELETE_ADMIN_PRODUCT = 'DELETE_ADMIN_PRODUCT';
const DELETE_ADMIN_PRODUCT_SUCCESS = 'DELETE_ADMIN_PRODUCT_SUCCESS';
const DELETE_ADMIN_PRODUCT_FAILURE = 'DELETE_ADMIN_PRODUCT_FAILURE';

const ACTIVE_ADMIN_PRODUCT = 'ACTIVE_ADMIN_PRODUCT';
const ACTIVE_ADMIN_PRODUCT_SUCCESS = 'ACTIVE_ADMIN_PRODUCT_SUCCESS';
const ACTIVE_ADMIN_PRODUCT_FAILURE = 'ACTIVE_ADMIN_PRODUCT_FAILURE';


export {
    GET_ADMIN_PRODUCT,
    GET_ADMIN_PRODUCT_SUCCESS,
    GET_ADMIN_PRODUCT_FAILURE,
    
    CREATE_ADMIN_PRODUCT,
    CREATE_ADMIN_PRODUCT_SUCCESS,
    CREATE_ADMIN_PRODUCT_FAILURE,

    UPDATE_ADMIN_PRODUCT,
    UPDATE_ADMIN_PRODUCT_SUCCESS,
    UPDATE_ADMIN_PRODUCT_FAILURE,

    DELETE_ADMIN_PRODUCT,
    DELETE_ADMIN_PRODUCT_SUCCESS,
    DELETE_ADMIN_PRODUCT_FAILURE,

    ACTIVE_ADMIN_PRODUCT,
    ACTIVE_ADMIN_PRODUCT_SUCCESS,
    ACTIVE_ADMIN_PRODUCT_FAILURE,
}

export const getAdminProduct = (params, callback) => doAction(GET_ADMIN_PRODUCT, params, callback);
export const getAdminProductSuccess = (data) => doResponseAction(GET_ADMIN_PRODUCT_SUCCESS, data);
export const getAdminProductFailure = (data) => doResponseAction(GET_ADMIN_PRODUCT_FAILURE, data);

export const createAdminProduct = (params, callback) => doAction(CREATE_ADMIN_PRODUCT, params, callback);
export const createAdminProductSuccess = (data) => doResponseAction(CREATE_ADMIN_PRODUCT_SUCCESS, data);
export const createAdminProductFailure = (data) => doResponseAction(CREATE_ADMIN_PRODUCT_FAILURE, data);

export const updateAdminProduct = (params, callback) => doAction(UPDATE_ADMIN_PRODUCT, params, callback);
export const updateAdminProductSuccess = (data) => doResponseAction(UPDATE_ADMIN_PRODUCT_SUCCESS, data);
export const updateAdminProductFailure = (data) => doResponseAction(UPDATE_ADMIN_PRODUCT_FAILURE, data);

export const deleteAdminProduct = (params, callback) => doAction(DELETE_ADMIN_PRODUCT, params, callback);
export const deleteAdminProductSuccess = (data) => doResponseAction(DELETE_ADMIN_PRODUCT_SUCCESS, data);
export const deleteAdminProductFailure = (data) => doResponseAction(DELETE_ADMIN_PRODUCT_FAILURE, data);

export const activeAdminProduct = (params, callback) => doAction(ACTIVE_ADMIN_PRODUCT, params, callback);
export const activeAdminProductSuccess = (data) => doResponseAction(ACTIVE_ADMIN_PRODUCT_SUCCESS, data);
export const activeAdminProductFailure = (data) => doResponseAction(ACTIVE_ADMIN_PRODUCT_FAILURE, data);
