import { all, fork } from 'redux-saga/effects';
import login from './loginSaga';
import store from './storeSaga';
import edge from './edgeSaga';
import camera from './cameraSaga';
import category from './categorySaga';
import adminProduct from './adminPoductSaga';
import vendorProduct from './vendorProductSaga';
import kiosk from './kioskSaga'
import adminOnboard from './adminOnboardSaga'
import notification from './notificationSaga'
import dashboard from './dashboardSaga'
export default function* root() {
  yield all([
    fork(login),
    fork(store),
    fork(edge),
    fork(camera),
    fork(category),
    fork(adminProduct),
    fork(vendorProduct),
    fork(kiosk),
    fork(adminOnboard),
    fork(notification),
    fork(dashboard),
  ]);
}