import { doAction, doResponseAction } from ".";

const GET_VENDOR_PRODUCT = 'GET_VENDOR_PRODUCT';
const GET_VENDOR_PRODUCT_SUCCESS = 'GET_VENDOR_PRODUCT_SUCCESS';
const GET_VENDOR_PRODUCT_FAILURE = 'GET_VENDOR_PRODUCT_FAILURE';


const CREATE_VENDOR_PRODUCT = 'CREATE_VENDOR_PRODUCT';
const CREATE_VENDOR_PRODUCT_SUCCESS = 'CREATE_VENDOR_PRODUCT_SUCCESS';
const CREATE_VENDOR_PRODUCT_FAILURE = 'CREATE_VENDOR_PRODUCT_FAILURE';

const UPDATE_VENDOR_PRODUCT = 'UPDATE_VENDOR_PRODUCT';
const UPDATE_VENDOR_PRODUCT_SUCCESS = 'UPDATE_VENDOR_PRODUCT_SUCCESS';
const UPDATE_VENDOR_PRODUCT_FAILURE = 'UPDATE_VENDOR_PRODUCT_FAILURE';

const DELETE_VENDOR_PRODUCT = 'DELETE_VENDOR_PRODUCT';
const DELETE_VENDOR_PRODUCT_SUCCESS = 'DELETE_VENDOR_PRODUCT_SUCCESS';
const DELETE_VENDOR_PRODUCT_FAILURE = 'DELETE_VENDOR_PRODUCT_FAILURE';

const ACTIVE_VENDOR_PRODUCT = 'ACTIVE_VENDOR_PRODUCT';
const ACTIVE_VENDOR_PRODUCT_SUCCESS = 'ACTIVE_VENDOR_PRODUCT_SUCCESS';
const ACTIVE_VENDOR_PRODUCT_FAILURE = 'ACTIVE_VENDOR_PRODUCT_FAILURE';


export {
    GET_VENDOR_PRODUCT,
    GET_VENDOR_PRODUCT_SUCCESS,
    GET_VENDOR_PRODUCT_FAILURE,
    
    CREATE_VENDOR_PRODUCT,
    CREATE_VENDOR_PRODUCT_SUCCESS,
    CREATE_VENDOR_PRODUCT_FAILURE,

    UPDATE_VENDOR_PRODUCT,
    UPDATE_VENDOR_PRODUCT_SUCCESS,
    UPDATE_VENDOR_PRODUCT_FAILURE,

    DELETE_VENDOR_PRODUCT,
    DELETE_VENDOR_PRODUCT_SUCCESS,
    DELETE_VENDOR_PRODUCT_FAILURE,

    ACTIVE_VENDOR_PRODUCT,
    ACTIVE_VENDOR_PRODUCT_SUCCESS,
    ACTIVE_VENDOR_PRODUCT_FAILURE,
}

export const getVendorProduct = (params, callback) => doAction(GET_VENDOR_PRODUCT, params, callback);
export const getVendorProductSuccess = (data) => doResponseAction(GET_VENDOR_PRODUCT_SUCCESS, data);
export const getVendorProductFailure = (data) => doResponseAction(GET_VENDOR_PRODUCT_FAILURE, data);

export const createVendorProduct = (params, callback) => doAction(CREATE_VENDOR_PRODUCT, params, callback);
export const createVendorProductSuccess = (data) => doResponseAction(CREATE_VENDOR_PRODUCT_SUCCESS, data);
export const createVendorProductFailure = (data) => doResponseAction(CREATE_VENDOR_PRODUCT_FAILURE, data);

export const updateVendorProduct = (params, callback) => doAction(UPDATE_VENDOR_PRODUCT, params, callback);
export const updateVendorProductSuccess = (data) => doResponseAction(UPDATE_VENDOR_PRODUCT_SUCCESS, data);
export const updateVendorProductFailure = (data) => doResponseAction(UPDATE_VENDOR_PRODUCT_FAILURE, data);

export const deleteVendorProduct = (params, callback) => doAction(DELETE_VENDOR_PRODUCT, params, callback);
export const deleteVendorProductSuccess = (data) => doResponseAction(DELETE_VENDOR_PRODUCT_SUCCESS, data);
export const deleteVendorProductFailure = (data) => doResponseAction(DELETE_VENDOR_PRODUCT_FAILURE, data);

export const activeVendorProduct = (params, callback) => doAction(ACTIVE_VENDOR_PRODUCT, params, callback);
export const activeVendorProductSuccess = (data) => doResponseAction(ACTIVE_VENDOR_PRODUCT_SUCCESS, data);
export const activeVendorProductFailure = (data) => doResponseAction(ACTIVE_VENDOR_PRODUCT_FAILURE, data);
