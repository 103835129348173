import React, { useEffect, useState } from "react"
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import LineChart from "../component/LineChart";
import LineChartHorizontal from "../component/LineChartHorizontal";
import VerticalBarChart from "../component/VerticalBarChart";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux/action";
import StoreTypeAhead from "../component/StoreTypeAhead";
import Area from "../component/AreaChart";
import PieChart from "../component/PieChart";
import Graph from "../asset/img/graph.png"
import GraphCount from "../asset/img/graphcount.png"
import GraphJourney from "../asset/img/graphjourney.png"
import { preventedProductAnalytic } from "../redux/reducer/dashboardReducer";
import { getLoginData } from "../redux/reducer/loginReducer";
const Dashboard = () => {
    const dispatch = useDispatch()
    const prevendtedProduct = useSelector(preventedProductAnalytic)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedTrending, setSelectedTrending] = useState('Value');
    const [selectedBottom, setSelectedBottom] = useState('Saved Product');
    const [selectedTimeChart, setSelectedTimeChart] = useState();
    const [selectedStore, setSelectedStore] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('');
    const userData = useSelector(getLoginData)

    useEffect(() => {
        let params = {
            country: selectedCountry?.name,
            state: selectedState?.name,
            city: selectedCity?.name,
            store_id: selectedStore?.value,
            filter: selectedFilter
        }
        dispatch(Actions.getJourney(params))
        dispatch(Actions.getTheftAnalysis(params))
        dispatch(Actions.getHighValueProduct(params))
        dispatch(Actions.getTotalSavedProduct(params))
        dispatch(Actions.getTimeAnalysisProduct(params))
        dispatch(Actions.getPreventedProductAnalytic())
    }, [selectedCountry, selectedState, selectedCity, selectedStore, selectedFilter, selectedBottom, selectedTrending]);
    useEffect(() => {
        let params = {
            filter: selectedTimeChart
        }
        dispatch(Actions.getTimeAnalysisProduct(params))
    }, [dispatch, selectedTimeChart]);

    const handleFieldChange = (field) => {
        setSelectedFilter(field)
    };
    const handleFieldCountry = (field) => {
        setSelectedCountry(field)
        setSelectedState('')
        setSelectedCity('')
    };
    const handleFieldState = (field) => {
        setSelectedState(field)
        setSelectedCity('')
    };
    const handleToggle = (toggleString) => {
        setSelectedTrending(toggleString)
        dispatch(Actions.getHighValueProduct())
    }
    const handleToggleBottom = (toggleString) => {
        setSelectedBottom(toggleString)
        dispatch(Actions.getTotalSavedProduct())
    }
    const formatCurrency = (value) => {
        if (value >= 1000000) {
            return '$ ' + (value / 1000000)?.toFixed(1) + 'M';
        } else if (value >= 1000) {
            return '$ ' + (value / 1000)?.toFixed(1) + 'K';
        } else {
            return '$ ' + value?.toFixed(2);
        }
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard_content">
                <div className="right_side right_side_mid right_side_cover ">
                    <div className="body_content">
                        <div className="body_header">
                            <p className="m-0 title">Dashboard</p>
                        </div>
                        <div className="row mt-3">
                            <div className="col-4">
                                <div className="card_design">
                                    <div className="card_align">
                                        <div className="icon_align icon_color mb-3"><i class="bi bi-currency-dollar card_icon"></i></div>
                                        <h4 className="mb-1">{prevendtedProduct?.total_product_price ? formatCurrency(prevendtedProduct?.total_product_price) : 0}</h4>
                                        <p className="">Prevented Product Price</p>
                                    </div>
                                    <img src={Graph} className="graph_design" alt="graph" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card_design">
                                    <div className="card_align">
                                        <div className="icon_align icon_color_count mb-3"><i class="bi bi-list-ol card_icon_count"></i></div>
                                        <h4 className="mb-1">{prevendtedProduct?.total_error_product_count ? prevendtedProduct?.total_error_product_count : 0}</h4>
                                        <p className="">Prevented Product Count</p>
                                    </div>
                                    <img src={GraphCount} className="graph_design" alt="graph" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card_design">
                                    <div className="card_align">
                                        <div className="icon_align icon_color_journey mb-3"><i class="bi bi-check-lg card_icon_journey"></i></div>
                                        <h4 className="mb-1">{prevendtedProduct?.total_journey ? prevendtedProduct?.total_journey : 0}</h4>
                                        <p className="">Prevented journey</p>
                                    </div>
                                    <img src={GraphJourney} className="graph_design" alt="graph" />
                                </div>
                            </div>
                        </div>

                        {/* <!-- dashbard start --> */}
                        <div className="dashboard">
                            <div>
                                {/* <div>
                                    <p className="m-0 table_heading">Loss Prevention</p>
                                </div> */}
                                <div className="row g-3 mt-3">
                                    <div className={userData?.role_id === 3 ? "col-md-3" : "col-md-4"}>
                                        <label htmlFor="inputState" className="form-label">Country</label>
                                        <Select
                                            options={Country?.getAllCountries()}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCountry}
                                            onChange={(item) => {
                                                handleFieldCountry(item)
                                            }}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className={userData?.role_id === 3 ? "col-md-3" : "col-md-4"}>
                                        <label htmlFor="inputState" className="form-label">State</label>
                                        <Select
                                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedState}
                                            onChange={(item) => {
                                                handleFieldState(item)
                                            }}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className={userData?.role_id === 3 ? "col-md-3" : "col-md-4"}>
                                        <label htmlFor="inputState" className="form-label">City</label>
                                        <Select
                                            options={City?.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCity}
                                            onChange={(item) => {
                                                setSelectedCity(item);
                                            }}
                                            isClearable={true}
                                        />
                                    </div>
                                    {userData?.role_id !== 3 && <div className="col-md-6">
                                        <label htmlFor="inputEmail4" className="form-label">Store</label>
                                        <StoreTypeAhead
                                            id="storeTypeAhead"
                                            style={{ margin: '10px' }}
                                            placeholder="Select Store"
                                            isMulti={false}
                                            onChange={(item) => setSelectedStore(item)}
                                            disabled
                                        />
                                    </div>}
                                    <div className={userData?.role_id === 3 ? "col-md-3" : "col-md-6"}>
                                        <label htmlFor="inputState" className="form-label">Filter</label>
                                        <select
                                            className="form-select"
                                            value={selectedFilter}
                                            onChange={(e) => handleFieldChange(e.target.value)}
                                        >
                                            <option value="">No filter</option>
                                            <option value="current_day">Current Day</option>
                                            <option value="previous_day">Previous Day</option>
                                            <option value="current_week">Current Week</option>
                                            <option value="previous_week">Previous Week</option>
                                            <option value="current_month">Current Month</option>
                                            <option value="previous_month">Previous Month</option>
                                            <option value="current_year">Current Year</option>
                                            <option value="previous_year">Previous Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="row row-cols-1 g-4 m-0">
                                        <div className="col-md-12 ps-md-0">
                                            <div className="card dashboard_left h-100">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <p className="m-0 card_text">Suspicious Overview</p>
                                                </div>
                                                <LineChart filter={selectedFilter} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 g-4 m-0">
                                        <div className="col-md-4 ps-md-0">
                                            <div className="card dashboard_right h-100">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <p className="m-0 card_text">Theft analysis</p>
                                                </div>
                                                <PieChart filter={selectedFilter} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 ps-md-0">
                                            <div className="card dashboard_right h-100">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <p className="m-0 card_text">Trending Chart</p>
                                                    <div className="d-flex gap-3 trend">
                                                        <button onClick={() => handleToggle('Value')} className={`trend_btn ${selectedTrending === 'Value' ? 'active' : ''}`}>Value</button>
                                                        <button onClick={() => handleToggle('Volume')} className={`trend_btn ${selectedTrending === 'Volume' ? 'active' : ''}`}>Volume</button>
                                                    </div>
                                                </div>
                                                <LineChartHorizontal
                                                    selectedTrending={selectedTrending} filter={selectedFilter}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 ps-md-0">
                                            <div className="card dashboard_right h-100">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <p className="m-0 card_text">Time analysis</p>
                                                </div>
                                                <VerticalBarChart selectedTimeChart={selectedTimeChart} filter={selectedFilter} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 g-4 m-0">
                                        <div className="col-md-12 ps-md-0">
                                            <div className="card dashboard_right h-100">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <p className="m-0 card_text">Saved Analysis</p>
                                                    <div className="d-flex gap-3 trend">
                                                        <button onClick={() => handleToggleBottom('Saved Product')} className={`trend_btn ${selectedBottom === 'Saved Product' ? 'active' : ''}`}>Count</button>
                                                        <button onClick={() => handleToggleBottom('Saved Price')} className={`trend_btn ${selectedBottom === 'Saved Price' ? 'active' : ''}`}>Price</button>
                                                    </div>
                                                </div>
                                                <Area selectedBottom={selectedBottom} filter={selectedFilter} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- dashboard end --> */}

                        {/* <!-- dashboard footer start --> */}
                        <div className="body_footer">
                            <p className="m-0 text-center mt-4">© All Rights Reserved by ♥ CVI</p>
                        </div>
                        {/* <!-- dashboard footer end --> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard