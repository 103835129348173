import { doAction, doResponseAction } from ".";

const GET_CAMERA = 'GET_CAMERA';
const GET_CAMERA_SUCCESS = 'GET_CAMERA_SUCCESS';
const GET_CAMERA_FAILURE = 'GET_CAMERA_FAILURE';


const CREATE_CAMERA = 'CREATE_CAMERA';
const CREATE_CAMERA_SUCCESS = 'CREATE_CAMERA_SUCCESS';
const CREATE_CAMERA_FAILURE = 'CREATE_CAMERA_FAILURE';

const UPDATE_CAMERA = 'UPDATE_CAMERA';
const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS';
const UPDATE_CAMERA_FAILURE = 'UPDATE_CAMERA_FAILURE';

const DELETE_CAMERA = 'DELETE_CAMERA';
const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS';
const DELETE_CAMERA_FAILURE = 'DELETE_CAMERA_FAILURE';

const ACTIVE_CAMERA = 'ACTIVE_CAMERA';
const ACTIVE_CAMERA_SUCCESS = 'ACTIVE_CAMERA_SUCCESS';
const ACTIVE_CAMERA_FAILURE = 'ACTIVE_CAMERA_FAILURE';


export {
    GET_CAMERA,
    GET_CAMERA_SUCCESS,
    GET_CAMERA_FAILURE,
    
    CREATE_CAMERA,
    CREATE_CAMERA_SUCCESS,
    CREATE_CAMERA_FAILURE,

    UPDATE_CAMERA,
    UPDATE_CAMERA_SUCCESS,
    UPDATE_CAMERA_FAILURE,

    DELETE_CAMERA,
    DELETE_CAMERA_SUCCESS,
    DELETE_CAMERA_FAILURE,

    ACTIVE_CAMERA,
    ACTIVE_CAMERA_SUCCESS,
    ACTIVE_CAMERA_FAILURE,
}

export const getCamera = (params, callback) => doAction(GET_CAMERA, params, callback);
export const getCameraSuccess = (data) => doResponseAction(GET_CAMERA_SUCCESS, data);
export const getCameraFailure = (data) => doResponseAction(GET_CAMERA_FAILURE, data);

export const createCamera = (params, callback) => doAction(CREATE_CAMERA, params, callback);
export const createCameraSuccess = (data) => doResponseAction(CREATE_CAMERA_SUCCESS, data);
export const createCameraFailure = (data) => doResponseAction(CREATE_CAMERA_FAILURE, data);

export const updateCamera = (params, callback) => doAction(UPDATE_CAMERA, params, callback);
export const updateCameraSuccess = (data) => doResponseAction(UPDATE_CAMERA_SUCCESS, data);
export const updateCameraFailure = (data) => doResponseAction(UPDATE_CAMERA_FAILURE, data);

export const deleteCamera = (params, callback) => doAction(DELETE_CAMERA, params, callback);
export const deleteCameraSuccess = (data) => doResponseAction(DELETE_CAMERA_SUCCESS, data);
export const deleteCameraFailure = (data) => doResponseAction(DELETE_CAMERA_FAILURE, data);

export const activeCamera = (params, callback) => doAction(ACTIVE_CAMERA, params, callback);
export const activeCameraSuccess = (data) => doResponseAction(ACTIVE_CAMERA_SUCCESS, data);
export const activeCameraFailure = (data) => doResponseAction(ACTIVE_CAMERA_FAILURE, data);
