import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { vendorProductDetailsValidateField } from "../utils/Validation";
import { vendorProductList, totalItemCount } from '../redux/reducer/vendorProductReducer';
import { toast } from "react-toastify";
import { PaginationComponent } from "../component/Pagination";
import AppLoader from "../component/AppLoader";
import { DeclinePopup } from "../component/DeclinePopup";
import StoreTypeAhead from "../component/StoreTypeAhead";
import { storeActiveList } from '../redux/reducer/storeReducer';
import { adminActiveProductList } from '../redux/reducer/adminProductReducer';
import { getLoginData } from "../redux/reducer/loginReducer";
import AdminProductTypeAhead from "../component/AdminProductTypeAhead";
import MESSAGE from "../constant/String";

const VendorProduct = () => {
    const dispatch = useDispatch()
    const vendorProductListData = useSelector(vendorProductList)
    const totalCount = useSelector(totalItemCount)
    const userData = useSelector(getLoginData)
    const activeStoreList = useSelector(storeActiveList)
    const adminActiveProduct = useSelector(adminActiveProductList)
    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [showAddList, setShowAddList] = useState(false);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [selectedStore, setSelectedStore] = useState("");
    const [selectSearch, setSelectedSearch] = useState()
    const [vendorProductDetails, setVendorProductDetails] = useState({
        name: "",
        price: "",
        group_id: "",
        admin_product_id: "",
        store_id: "",
    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        price: false,
        group_id: false,
        admin_product_id: false,
        store_id: false,
    });

    const getVendorProductList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            store_id: selectedStore?.value,
            search: selectSearch
        }
        setLoading(true)
        dispatch(Actions.getVendorProduct(params, callback))
    }, [dispatch, pageNumber, selectedStore, selectSearch])

    useEffect(() => {
        getVendorProductList()
        dispatch(Actions.activeCategory())
    }, [dispatch, getVendorProductList])

    const handleFieldChange = (field, value) => {
        setVendorProductDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleAddButtonClick = () => {
        setShowAddList(true);
        setVendorProductDetails({
            name: "",
            price: "",
            group_id: "",
            admin_product_id: "",
            store_id: "",

        })
    };
    const handleBackButtonClick = () => {
        setShowAddList(false);
        setVendorProductDetails({
            name: "",
            price: "",
            group_id: "",
            store_id: "",
            admin_product_id: "",

        })
        setFieldChangedStatus(() => ({
            name: false,
            price: false,
            group_id: false,
            admin_product_id: false,
            store_id: false,
        }));
    };
    const onEdit = (obj) => {
        const { name, price, group_id, training_id, store, product, id } = obj;
        const selectedStoreItem = activeStoreList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === store);
        const selectedAdminProductItem = adminActiveProduct?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === product);
        setVendorProductDetails({
            id: id,
            name: name,
            price: price,
            group_id: group_id,
            training_id: training_id,
            store_id: selectedStoreItem,
            admin_product_id: selectedAdminProductItem
        });
        setShowAddList(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        let hasErrors = false;

        const handleValidation = (field) => {
            if ((field === 'store_id' && userData?.role_id === 3) || field === 'admin_product_id') {
                newErrors[field] = false;
                return;
            }

            const isValid = vendorProductDetailsValidateField(field, vendorProductDetails?.[field]);

            newErrors[field] = !isValid;

            if (isValid) {
                hasErrors = true;
            }
        };

        for (const field in vendorProductDetails) {
            handleValidation(field);
        }

        setFieldChangedStatus(newErrors);

        if (!hasErrors) {
            setLoading(true);

            const callback = (response) => {
                setLoading(false);

                if (response?.response_code === 0) {
                    handleBackButtonClick();
                    getVendorProductList();
                    toast.success(response?.response_message);
                } else {
                    toast.error(response?.response_message);
                }
            };

            const params = {
                id: vendorProductDetails?.id,
                name: vendorProductDetails?.name,
                price: vendorProductDetails?.price,
                group_id: vendorProductDetails.group_id,
                store_id: vendorProductDetails?.store_id?.value,
                admin_product_id: vendorProductDetails?.admin_product_id?.value,
            };

            if (vendorProductDetails?.id) {
                dispatch(Actions.updateVendorProduct(params, callback));
            } else {
                delete params.id;
                dispatch(Actions.createVendorProduct(params, callback));
            }
        }
    };
    const activeVendorProduct = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick();
                getVendorProductList();
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "vendorProduct_id": itemObj.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteVendorProduct(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (obj) => {
        setItemObj(obj);
        setOpenDeclineModal(true);
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const handleStoreSelect = (selected) => {
        setVendorProductDetails({ ...vendorProductDetails, 'store_id': selected })
    };
    const handleAdminProductSelect = (selected) => {
        setVendorProductDetails({ ...vendorProductDetails, 'admin_product_id': selected, "group_id": selected?.group_id })

    };
    const onStoreChange = (selected) => {
        setPageNumber(1)
        setSelectedStore(selected)
    };
    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard">
                {isLoading && <AppLoader />}
                {!showAddList && <div className="vision_dashboard_content">
                    <div className="right_side right_side_mid right_side_cover ">
                        <div className="body_content">
                            <div className="body_header">
                                <p className="m-0 title">Vendor Product</p>
                                <div className="d-flex gap-2">
                                    <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(false)}>Vendor Product list</p>
                                </div>
                            </div>
                            {/* <!-- add button start --> */}
                            <div className="d-md-flex justify-content-between my-4 z-5">
                                {userData?.role_id !== 3 ? <div className="d-md-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search product name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                    <StoreTypeAhead
                                        className="dropdown_wrap"
                                        onChange={onStoreChange}
                                        id="StoreTypeAhead"
                                        style={{ margin: '10px' }}
                                        placeholder="Select the Store"
                                        isMulti={false}
                                        disabled
                                        defaultValue={selectedStore}
                                    />
                                </div> : <div className="d-md-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search product name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                </div>}
                                <button className="add_btn" onClick={(handleAddButtonClick)}><p className="m-0">Add</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                </button>
                            </div>
                            {/* <!-- add button end --> */}
                            {/* <!-- table start --> */}
                            <div className="table_bg">
                                <div className="table_body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(vendorProductListData) && vendorProductListData?.map((obj, index) => (
                                                <tr key={obj?.id}>
                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                    <td>{obj?.name}</td>
                                                    <td>{obj?.price}</td>
                                                    <td>{obj?.is_active ? <p className="m-0 status_td">Active</p> : <p className="m-0 inactive_td">In Active</p>}</td>
                                                    <td>
                                                        <div className="action_td">
                                                            <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    strokeWidth="1.5" stroke="#1a9f53" width="16" height="16" >
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                                </svg>
                                                            </button>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckChecked"
                                                                    checked={obj?.is_active}
                                                                    onClick={(e) => handleClick(obj)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {Array.isArray(vendorProductListData) && vendorProductListData?.length == 0 && (
                                                <tr className="tr-shadow">
                                                    <td colSpan="6" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {Array.isArray(vendorProductListData) && vendorProductListData?.length > 0 &&
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                    </div>}
                            </div>
                            {/* <!-- table end --> */}
                        </div>
                    </div>
                </div>}
                {showAddList && <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Vendor Product</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 pointer-hover" onClick={() => handleBackButtonClick()}>Vendor Product list</p>
                                        <p className="m-0">~</p>
                                        <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(true)}> {vendorProductDetails?.id ? "Update VendorProduct" : "Create VendorProduct"}</p>
                                    </div>
                                </div>
                                {/* <!-- Navigation Button end --> */}
                                {/* <!-- VendorProduct Info Start --> */}
                                <div className="list_form mt-4">
                                    <div className="d-flex justify-content-between align-items-center"><p className="m-0 table_heading">Vendor Product Information</p>
                                        <button className="add_btn" onClick={() => handleBackButtonClick()}><p className="m-0">Back</p></button>
                                    </div>
                                    <div className="row g-3 mt-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Product Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter product name"
                                                value={vendorProductDetails?.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.name && vendorProductDetailsValidateField('name', vendorProductDetails?.name) && (
                                                <span className="text-danger">{vendorProductDetailsValidateField('name', vendorProductDetails?.name)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Price</label>
                                            <input
                                                type="text" maxLength="10"
                                                className="form-control"
                                                placeholder="Enter vendorProduct price"
                                                value={vendorProductDetails?.price}
                                                onChange={(e) => handleFieldChange('price', e.target.value)}
                                            />
                                            {fieldChangedStatus?.price && vendorProductDetailsValidateField('price', vendorProductDetails?.price) && (
                                                <span className="text-danger">{vendorProductDetailsValidateField('price', vendorProductDetails?.price)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Admin Product</label>
                                            <AdminProductTypeAhead
                                                id="adminProductTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Admin Product"
                                                isMulti={false}
                                                defaultValue={vendorProductDetails?.admin_product_id}
                                                onChange={handleAdminProductSelect}
                                                disabled
                                            />
                                            {fieldChangedStatus?.admin_product_id && vendorProductDetailsValidateField('admin_product_id', vendorProductDetails?.admin_product_id) && (
                                                <span className="text-danger">{vendorProductDetailsValidateField('admin_product_id', vendorProductDetails?.admin_product_id)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Group Id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter the group Id"
                                                value={vendorProductDetails?.group_id}
                                                onChange={(e) => handleFieldChange('group_id', e.target.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.group_id && vendorProductDetailsValidateField('group_id', vendorProductDetails?.group_id) && (
                                                <span className="text-danger">{vendorProductDetailsValidateField('group_id', vendorProductDetails?.group_id)}</span>
                                            )}
                                        </div>

                                        {userData?.role_id !== 3 && <div className="col-md-6">
                                            <label className="form-label">Store</label>
                                            <StoreTypeAhead
                                                id="storeTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select store"
                                                isMulti={false}
                                                defaultValue={vendorProductDetails?.store_id}
                                                onChange={handleStoreSelect}
                                                disabled
                                            />
                                            {fieldChangedStatus?.store_id && vendorProductDetailsValidateField('store_id', vendorProductDetails?.store_id) && (
                                                <span className="text-danger">{vendorProductDetailsValidateField('store_id', vendorProductDetails?.store_id)}</span>
                                            )}
                                        </div>}
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 col-4 mx-auto mt-4">
                                        <button onClick={handleSubmit} className="upload_btn" type="button">{vendorProductDetails?.id ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                {/* <!-- VendorProduct Info end --> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => activeVendorProduct()}
                />}
            </div>
        </React.Fragment>
    )
}

export default VendorProduct