import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_ADMIN_PRODUCT, UPDATE_ADMIN_PRODUCT,DELETE_ADMIN_PRODUCT,GET_ADMIN_PRODUCT,ACTIVE_ADMIN_PRODUCT } from '../action/adminProductAction';

export function* getAdminProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_ADMIN_PRODUCT, params)
        yield put(Actions.getAdminProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getAdminProductFailure(error))
    }
}
export function* createAdminProduct({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_ADMIN_PRODUCT, params)
        yield put(Actions.createAdminProductSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createAdminProductFailure(error))
    }
}
export function* updateAdminProduct({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_ADMIN_PRODUCT, params)
        yield put(Actions.updateAdminProductSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateAdminProductFailure(error))
    }
}
export function* deleteAdminProduct({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_ADMIN_PRODUCT, params)
        yield put(Actions.deleteAdminProductSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteAdminProductFailure(error))
    }
}
export function* activeAdminProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_ADMIN_PRODUCT, params)
        yield put(Actions.activeAdminProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeAdminProductFailure(error))
    }
}
export default function* adminProductWatcher() {
    yield all([
        takeLatest(GET_ADMIN_PRODUCT, getAdminProduct),
        takeLatest(CREATE_ADMIN_PRODUCT, createAdminProduct),
        takeLatest(UPDATE_ADMIN_PRODUCT, updateAdminProduct),
        takeLatest(DELETE_ADMIN_PRODUCT, deleteAdminProduct),
        takeLatest(ACTIVE_ADMIN_PRODUCT, activeAdminProduct),

    ]);
}
