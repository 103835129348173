import { Actions } from '../action';

const initialState = {
    isLoading: false,
    journeyList: [],
    error: false,
    theftAnalysis:[],
    highValueProduct:[],
    highVolumeProduct:[],
    timeAnalysis:[],
    totalSavedProduct:[],
    totalSavedPrice:[],
    theftError:false,
    preventedProduct: []
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}

export const dashboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_JOURNEY: {
            return commonState(state);
        }
        case Actions.GET_JOURNEY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                journeyList: action?.data,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_JOURNEY_FAILURE: {
            return commonFailer(state, action);
        }

        case Actions.GET_THEFT_ANALYSIS: {
            return commonState(state);
        }
        case Actions.GET_THEFT_ANALYSIS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                theftAnalysis: action?.data,
            };
        }
        case Actions.GET_THEFT_ANALYSIS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                theftError: action.error,
            };
        }
        case Actions.GET_HIGH_VALUE_PRODUCT: {
            return commonState(state);
        }
        case Actions.GET_HIGH_VALUE_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                highValueProduct: action?.data,
                error: false
            };
        }
        case Actions.GET_HIGH_VALUE_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.GET_HIGH_VOLUME_PRODUCT: {
            return commonState(state);
        }
        case Actions.GET_HIGH_VOLUME_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                highVolumeProduct: action?.data,
                error: false
            };
        }
        case Actions.GET_HIGH_VOLUME_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.GET_TIME_ANALYSIS: {
            return commonState(state);
        }
        case Actions.GET_TIME_ANALYSIS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                timeAnalysis: action?.data,
                error: false
            };
        }
        case Actions.GET_TIME_ANALYSIS_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.GET_TOTAL_SAVED_PRODUCT: {
            return commonState(state);
        }
        case Actions.GET_TOTAL_SAVED_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                totalSavedProduct: action?.data,
                error: false
            };
        }
        case Actions.GET_TOTAL_SAVED_PRODUCT_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.GET_TOTAL_SAVED_PRICE: {
            return commonState(state);
        }
        case Actions.GET_TOTAL_SAVED_PRICE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                totalSavedPrice: action?.data,
                error: false
            };
        }
        case Actions.GET_TOTAL_SAVED_PRICE_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.GET_PREVENTED_PRODUCT_ANALYTIC: {
            return commonState(state);
        }
        case Actions.GET_PREVENTED_PRODUCT_ANALYTIC_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                preventedProduct: action?.data,
                error: false
            };
        }
        case Actions.GET_PREVENTED_PRODUCT_ANALYTIC_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const journeyList = (state) => state?.DASHBOARD?.journeyList;
export const theftAnalysis = (state) => state?.DASHBOARD?.theftAnalysis;
export const highValueItem = (state) => state?.DASHBOARD?.highValueProduct;
export const highVolumeItem = (state) => state?.DASHBOARD?.highVolumeProduct;
export const timeAnalysisData = (state) => state?.DASHBOARD?.timeAnalysis;
export const totalSavedProductItem = (state) => state?.DASHBOARD?.totalSavedProduct;
export const totalSavedPriceItem = (state) => state?.DASHBOARD?.totalSavedPrice;
export const preventedProductAnalytic = (state) => state?.DASHBOARD?.preventedProduct;


