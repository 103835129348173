import React, { useState } from 'react';
import loginImage from '../asset/img/login_img.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/action';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { userEmailId } from '../redux/reducer/loginReducer';
import AppLoader from '../component/AppLoader';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userEmail = useSelector(userEmailId)
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        newPassword1: '',
        newPassword2: '',
    });

    const [errors, setErrors] = useState({
        newPassword1: '',
        newPassword2: '',
    });

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };
    const validatePassword = (password) => {
        // Password validation rules can be added here
        return password.length >= 8;
    };
    const handleSubmit = () => {
        // Reset errors
        setErrors({
            newPassword1: '',
            newPassword2: '',
        });

        // Perform validation
        let isValid = true;
        if (!validatePassword(formData.newPassword1)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword1: 'Password should be a minimum of 8 characters',
            }));
            isValid = false;
        }

        if (formData.newPassword1 !== formData.newPassword2) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPassword2: 'Passwords does not match',
            }));
            isValid = false;
        }

        if (isValid) {
            const callback = (response) => {
                setLoading(false)
                if (response?.response_code !== 0) {
                    toast.error(response?.response_message)
                } else {
                    toast.success(response?.response_message)
                    navigate("/")
                }
            }
            setLoading(true)
            dispatch(Actions.resetPasswordUser({ email: userEmail?.email, new_password: formData?.newPassword2 }, callback))
        }
    };

    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="login_main_screen">
                <div className="card login_card">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center d-none">
                            <img src={loginImage} className="img-fluid p-4" alt="Login" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="p-lg-0 px-md-5 p-4">
                                <p className="fs-24 f-sbold">RESET PASSWORD</p>
                                <p className="fs-14 f-reg">
                                    Please enter your new password
                                </p>
                                <div className="password mt-4">
                                    <label className="form-label fs-16 f-sbold mb-3">New Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword1 ? "text" : "password"}
                                            className="form-control select_options aisle_name"
                                            name="newPassword1"
                                            value={formData?.newPassword1}
                                            onChange={handleInputChange}
                                            placeholder="Enter new password"
                                        />
                                        <div onClick={togglePasswordVisibility1} className="input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}> {/* Reduced padding */}
                                                <FontAwesomeIcon
                                                    icon={showPassword1 ? faEye : faEyeSlash}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    {errors?.newPassword1 && <p className="text-danger">{errors?.newPassword1}</p>}
                                </div>
                                <div className="password mt-4">
                                    <label className="form-label fs-16 f-sbold mb-3">Confirm Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword2 ? "text" : "password"}
                                            className="form-control select_options aisle_name"
                                            name="newPassword2"
                                            value={formData?.newPassword2}
                                            onChange={handleInputChange}
                                            placeholder="Enter confirm password"
                                        />
                                        <div onClick={togglePasswordVisibility2} className="input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}> {/* Reduced padding */}
                                                <FontAwesomeIcon
                                                    icon={showPassword2 ? faEye : faEyeSlash}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    {errors?.newPassword2 && <p className="text-danger">{errors?.newPassword2}</p>}
                                </div>
                                <button className="btn login_btn mt-3" type="button" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
