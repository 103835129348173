import axios from 'axios';
import { store } from '../redux/store';
import END_POINTS from '../service/EndPoint';
import MESSAGE from '../constant/String';

const axiosInstance = axios.create({
    baseURL: END_POINTS.API_BASE_URL,
});

export const publicHeaders = () => {
    return {
        'Authorization': 'Basic YWRtaW46YWRtaW4=',
    }
}

export const httpAuthHeader = () => {
    const tokendata = store?.getState()?.USER;
    const { token } = tokendata;
    return {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
    }
}

const checkForRedirect = () => {
    localStorage.clear();
    setTimeout(() => {
        window.location.reload()
    }, 0);

}

//Check the api response and send the exact response to the callback
export const checkResponse = (response) => {
    if (typeof response?.data === 'string') {
        return response?.data;
    }
    if (response?.status === 200 && response?.data.response_code === 0) {
        return response?.data;
    } else if (response?.status === 200 && response?.data.response_code !== 0) {
        throw response?.data;
    } else if (response?.response?.status === 409) {
        throw response?.response?.data;
    } else if (response?.response?.status === 400) {
        throw response?.response?.data;
    } else if (response?.status !== 200) {
        throw MESSAGE?.SOMETHING_WENT_WORNG;
    }
    return response;
}


//Login
export const login = async (url, data) => {
    const config = {
        headers: publicHeaders(),
    };
    try {
        const response = await axiosInstance.post(url, data, config);
        return response.data;
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}


// Admin Request
export const doGet = async (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader(),
    };
    try {
        const response = await axiosInstance.get(url, config);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        throw error;
    }
}


export const doPost = async (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    try {
        const response = await axiosInstance.post(url, data, config);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}

export const doPostUnAuth = async (url, data) => {

    try {
        const response = await axiosInstance.post(url, data);
        return checkResponse(response);
    } catch (error) {
        if (error.response.status === 401) {
            return checkForRedirect();
        }
        if (error.response.status === 409) {
            return checkResponse(error);
        }
        if (error.response.status === 400) {
            return checkResponse(error);
        }
        throw error;
    }
}