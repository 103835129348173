import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { edgeDetailsValidateField } from "../utils/Validation";
import { edgeList, totalItemCount } from '../redux/reducer/edgeReducer';
import { storeActiveList } from '../redux/reducer/storeReducer';
import { toast } from "react-toastify";
import { DeclinePopup } from "../component/DeclinePopup";
import { PaginationComponent } from "../component/Pagination";
import AppLoader from "../component/AppLoader";
import StoreTypeAhead from "../component/StoreTypeAhead";
import { getLoginData } from "../redux/reducer/loginReducer";
import MESSAGE from "../constant/String";

const Edge = () => {
    const dispatch = useDispatch()
    const edgeListData = useSelector(edgeList)
    const totalCount = useSelector(totalItemCount)
    const activeStoreList = useSelector(storeActiveList)
    const userData = useSelector(getLoginData)
    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [showAddList, setShowAddList] = useState(false);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [selectedStore, setSelectedStore] = useState('');
    const [selectSearch, setSelectedSearch] = useState()
    const [edgeDetails, setEdgeDetails] = useState({
        name: "",
        mac_address: "",
        ip_address: "",
        store_id: ""
    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        mac_address: false,
        ip_address: false,
        store_id: false
    });


    const getEdgeList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            store_id: selectedStore?.value,
            search: selectSearch
        }
        setLoading(true)
        dispatch(Actions.getEdge(params, callback))
    }, [dispatch, pageNumber, selectedStore, selectSearch])

    useEffect(() => {
        getEdgeList()
        // dispatch(Actions.activeStore())
    }, [dispatch, getEdgeList])

    const handleFieldChange = (field, value) => {
        setEdgeDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleAddButtonClick = () => {
        setShowAddList(true);
        setEdgeDetails({
            name: "",
            mac_address: "",
            ip_address: "",
            store_id: ""
        })
        setFieldChangedStatus(() => ({
            name: false,
            mac_address: false,
            ip_address: false,
            store_id: false
        }));
    };
    const handleBackButtonClick = () => {
        setShowAddList(false);
        setEdgeDetails({
            name: "",
            mac_address: "",
            ip_address: "",
            store_id: ""
        })
        setFieldChangedStatus(() => ({
            name: false,
            mac_address: false,
            ip_address: false,
            store_id: false
        }))

    };
    const onEdit = (obj) => {
        const { name, mac_address, ip_address, store, id } = obj;
        const selectedStoreItem = activeStoreList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === store);
        setEdgeDetails({
            id: id,
            name: name,
            mac_address: mac_address,
            ip_address: ip_address,
            store_id: selectedStoreItem
        })
        setShowAddList(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        for (const field in edgeDetails) {
            if ((field === 'store_id') && (userData?.role_id === 3)) {
                newErrors[field] = false;
                continue;
            }
            if (edgeDetailsValidateField(field, edgeDetails?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);

        if (!hasErrors) {
            setLoading(true)
            const callback = (response) => {
                if (response?.response_code === 0) {
                    handleBackButtonClick()
                    getEdgeList()
                    toast.success(response?.response_message)
                } else {
                    toast.error(response?.response_message)
                }
            };
            let params = {
                id: edgeDetails?.id,
                name: edgeDetails?.name,
                mac_address: edgeDetails?.mac_address,
                ip_address: edgeDetails?.ip_address,
                store_id: edgeDetails?.store_id?.value
            }
            if (edgeDetails?.id) {
                dispatch(Actions.updateEdge(params, callback))
            } else {
                delete params.id;
                dispatch(Actions.createEdge(params, callback))
            }

        }
    };
    const deleteEdge = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick()
                getEdgeList()
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "edge_id": itemObj?.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteEdge(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (obj) => {
        setItemObj(obj);
        setOpenDeclineModal(true);
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const handleStoreSelect = (selected) => {
        setEdgeDetails({ ...edgeDetails, 'store_id': selected })
    };
    const onStoreChange = (selected) => {
        setPageNumber(1)
        setSelectedStore(selected)
        // getEdgeList(selected)
    };
    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard">
                {isLoading && <AppLoader />}
                {!showAddList && <div className="vision_dashboard_content">
                    <div className="right_side right_side_mid right_side_cover ">
                        <div className="body_content">
                            <div className="body_header">
                                <p className="m-0 title">Edge</p>
                                <div className="d-flex gap-2">
                                    <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(false)}>Edge list</p>
                                </div>
                            </div>

                            {/* <!-- add button start --> */}
                            <div className="d-md-flex justify-content-between my-4">
                                <div className="d-md-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search edge name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                    {userData?.role_id !== 3 ?
                                        <StoreTypeAhead
                                            className="dropdown_wrap"
                                            onChange={onStoreChange}
                                            id="StoreTypeAhead"
                                            style={{ margin: '10px' }}
                                            placeholder="Select the Store"
                                            isMulti={false}
                                            disabled
                                            defaultValue={selectedStore}
                                        />
                                        :
                                        null
                                    }
                                </div>
                                <button className="add_btn" onClick={() => handleAddButtonClick()}><p className="m-0">Add</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                </button>
                            </div>
                            {/* <!-- add button end --> */}
                            {/* <!-- table start --> */}
                            <div className="table_bg">
                                <div className="table_body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Edge Name</th>
                                                <th scope="col">Mac Address</th>
                                                <th scope="col">Ip Address</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(edgeListData) && edgeListData?.map((obj, index) => (
                                                <tr key={obj?.id}>
                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                    <td>{obj?.name}</td>
                                                    <td>{obj?.mac_address}</td>
                                                    <td>{obj?.ip_address}</td>
                                                    <td>{obj?.is_active ? <p className="m-0 status_td">Active</p> : <p className="m-0 inactive_td">In Active</p>}
                                                    </td>
                                                    <td>
                                                        <div className="action_td">
                                                            <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    strokeWidth="1.5" stroke="#1a9f53" width="16" height="16" >
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                                </svg>
                                                            </button>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckChecked"
                                                                    checked={obj?.is_active}
                                                                    onClick={() => handleClick(obj)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {Array.isArray(edgeListData) && edgeListData?.length == 0 && (
                                                <tr className="tr-shadow">
                                                    <td colSpan="6" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {Array.isArray(edgeListData) && edgeListData?.length > 0 &&
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                    </div>}
                            </div>
                            {/* <!-- table end --> */}
                        </div>
                    </div>
                </div>}
                {showAddList && <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Edge</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 pointer-hover" onClick={() => handleBackButtonClick()}>Edge list</p>
                                        <p className="m-0">~</p>
                                        <p className="m-0  title_active pointer-hover" onClick={() => setShowAddList(true)}>{edgeDetails?.id ? "Update Edge" : "Create Edge"}</p>
                                    </div>
                                </div>
                                {/* <!-- Navigation Button end --> */}
                                {/* <!-- add button start --> */}
                                <div className="list_form mt-4">
                                    <div className="d-flex justify-content-between align-items-center"><p className="m-0 table_heading">Edge Information</p>
                                        <button className="add_btn" onClick={() => handleBackButtonClick()}><p className="m-0">Back</p>
                                        </button>
                                    </div>
                                    <div className="row g-3 mt-3">
                                        <div className="col-md-6">
                                            <label className="form-label">Edge Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter edge name"
                                                value={edgeDetails?.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.name && edgeDetailsValidateField('name', edgeDetails?.name) && (
                                                <span className="text-danger">{edgeDetailsValidateField('name', edgeDetails?.name)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Mac Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Mac Address"
                                                value={edgeDetails?.mac_address}
                                                onChange={(e) => handleFieldChange('mac_address', e.target.value)}
                                            />
                                            {fieldChangedStatus?.mac_address && edgeDetailsValidateField('mac_address', edgeDetails?.mac_address) && (
                                                <span className="text-danger">{edgeDetailsValidateField('mac_address', edgeDetails?.mac_address)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Ip Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Ip Address"
                                                value={edgeDetails?.ip_address}
                                                onChange={(e) => handleFieldChange('ip_address', e?.target?.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.ip_address && edgeDetailsValidateField('ip_address', edgeDetails?.ip_address) && (
                                                <span className="text-danger">{edgeDetailsValidateField('ip_address', edgeDetails?.ip_address)}</span>
                                            )}
                                        </div>
                                        {userData?.role_id !== 3 && <div className="col-md-6">
                                            <label className="form-label">Store</label>
                                            <StoreTypeAhead
                                                id="WorkerTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Store"
                                                isMulti={false}
                                                defaultValue={edgeDetails?.store_id}
                                                onChange={handleStoreSelect}
                                                disabled
                                            />
                                            {fieldChangedStatus?.store_id && edgeDetailsValidateField('store_id', edgeDetails?.store_id) && (
                                                <span className="text-danger">{edgeDetailsValidateField('store_id', edgeDetails?.store_id)}</span>
                                            )}
                                        </div>}
                                    </div>
                                    <div className="d-grid gap-2 col-4 mx-auto mt-4">
                                        <button onClick={handleSubmit} className="upload_btn" type="button">{edgeDetails?.id ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                {/* <!-- Store Info end --> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => deleteEdge()}
                />}
            </div>
        </React.Fragment>
    )
}

export default Edge