import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { kioskDetailsValidateField } from "../utils/Validation";
import { kioskList, totalItemCount } from '../redux/reducer/kioskReducer';
import { toast } from "react-toastify";
import { PaginationComponent } from "../component/Pagination";
import AppLoader from "../component/AppLoader";
import { DeclinePopup } from "../component/DeclinePopup";
import StoreTypeAhead from "../component/StoreTypeAhead";
import EdgeTypeAhead from "../component/EdgeTypeAhead";
import CameraTypeAhead from "../component/CameraTypeAhead";
import { storeActiveList } from '../redux/reducer/storeReducer';
import { edgeActiveList } from '../redux/reducer/edgeReducer';
import { cameraActiveList } from '../redux/reducer/cameraReducer';
import { getLoginData } from "../redux/reducer/loginReducer";
import MESSAGE from "../constant/String";



const Kiosk = () => {
    const dispatch = useDispatch()
    const kioskListData = useSelector(kioskList)
    const totalCount = useSelector(totalItemCount)
    const activeStoreList = useSelector(storeActiveList)
    const activeEdgeList = useSelector(edgeActiveList)
    const activeCameraList = useSelector(cameraActiveList)
    const userData = useSelector(getLoginData)
    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [showAddList, setShowAddList] = useState(false);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [selectedStoreOption, setSelectedStoreOption] = useState([]);
    const [selectedEdgeOption, setSelectedEdgeOption] = useState([]);
    const [selectSearch, setSelectedSearch] = useState()
    const [kioskDetails, setKioskDetails] = useState({
        name: "",
        mac_address: "",
        ip_address: "",
        store_id: "",
        cv_edge_id: "",
        camera_id: "",
        checkout_type: "",
        kiosk_unique_id: ""
    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        mac_address: false,
        ip_address: false,
        store_id: false,
        cv_edge_id: false,
        camera_id: false,
        checkout_type: false,
        kiosk_unique_id: false
    });

    const getKioskList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            store_id: selectedStoreOption?.value,
            cv_edge_id: selectedEdgeOption?.value,
            search: selectSearch
        }
        setLoading(true)
        dispatch(Actions.getKiosk(params, callback))
    }, [dispatch, pageNumber, selectedEdgeOption?.value, selectedStoreOption?.value, selectSearch])

    useEffect(() => {
        getKioskList()
        dispatch(Actions.activeCamera())
    }, [dispatch, getKioskList])

    const handleFieldChange = (field, value) => {
        setKioskDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleAddButtonClick = () => {
        setShowAddList(true);
        setKioskDetails({
            name: "",
            mac_address: "",
            ip_address: "",
            store_id: "",
            cv_edge_id: "",
            camera_id: "",
            checkout_type: "",
            kiosk_unique_id: "",
        })
    };
    const handleBackButtonClick = () => {
        setShowAddList(false);
        setKioskDetails({
            name: "",
            mac_address: "",
            ip_address: "",
            store_id: "",
            cv_edge_id: "",
            camera_id: "",
            checkout_type: "",
            kiosk_unique_id: "",

        })
        setFieldChangedStatus(() => ({
            name: false,
            mac_address: false,
            ip_address: false,
            store_id: false,
            cv_edge_id: false,
            camera_id: false,
            checkout_type: false,
            kiosk_unique_id: false
        }));
        setSelectedStoreOption([])
        setSelectedEdgeOption([])
    };
    const onEdit = (obj) => {
        const { id, ip_address, mac_address, name, store, cv_edge, camera, checkout_type, kiosk_unique_id } = obj;
        const selectedStoreItem = activeStoreList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === store);
        const selectedEdgeItem = activeEdgeList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === cv_edge);
        const selectedCameraItem = activeCameraList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === camera);
        setKioskDetails({
            id: id,
            name: name,
            mac_address: mac_address,
            ip_address: ip_address,
            store_id: selectedStoreItem,
            cv_edge_id: selectedEdgeItem,
            camera_id: selectedCameraItem,
            checkout_type: checkout_type,
            kiosk_unique_id: kiosk_unique_id
        })
        setShowAddList(true);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        for (const field in kioskDetails) {
            if ((field === 'store_id') && (userData?.role_id === 3)) {
                newErrors[field] = false;
                continue;
            }
            if (kioskDetails?.checkout_type === 'traditional_checkout') {
                if (field === 'mac_address' || field === 'ip_address') {
                    newErrors[field] = false;
                    continue;
                }
            }
            if (kioskDetailsValidateField(field, kioskDetails?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);

        if (!hasErrors) {
            setLoading(true)
            const callback = (response) => {
                setLoading(false)
                if (response?.response_code === 0) {
                    handleBackButtonClick()
                    getKioskList();
                    toast.success(response?.response_message)
                } else {
                    toast.error(response?.response_message)
                }
            };
            let params = {
                id: kioskDetails?.id,
                name: kioskDetails?.name,
                mac_address: kioskDetails?.mac_address,
                ip_address: kioskDetails?.ip_address,
                store_id: kioskDetails?.store_id?.value,
                cv_edge_id: kioskDetails?.cv_edge_id?.value,
                camera_id: kioskDetails?.camera_id?.value,
                checkout_type: kioskDetails?.checkout_type,
                kiosk_unique_id: kioskDetails?.kiosk_unique_id
            }
            if (kioskDetails?.id) {
                dispatch(Actions.updateKiosk(params, callback))
            } else {
                delete params.id;
                dispatch(Actions.createKiosk(params, callback))
            }

        }
    };
    const activeKiosk = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick();
                getKioskList();
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "kiosk_id": itemObj?.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteKiosk(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (obj) => {
        setItemObj(obj);
        setOpenDeclineModal(true);
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const handleStoreSelect = (selected) => {
        setKioskDetails({ ...kioskDetails, 'store_id': selected, 'cv_edge_id': '', 'camera_id': '' })
    };
    const handleEdgeSelect = (selected) => {
        setKioskDetails({ ...kioskDetails, 'cv_edge_id': selected, 'camera_id': '' })
    };
    const handleCameraSelect = (selected) => {
        setKioskDetails({ ...kioskDetails, 'camera_id': selected })
    };
    const onStoreChange = (selected) => {
        setPageNumber(1)
        setSelectedStoreOption(selected)
        setSelectedEdgeOption([])
    };
    const onEdgeChange = (selected) => {
        setSelectedEdgeOption(selected?.value ? selected : [])
    };
    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard">
                {isLoading && <AppLoader />}
                {!showAddList && <div className="vision_dashboard_content">
                    <div className="right_side right_side_mid right_side_cover ">
                        <div className="body_content">
                            <div className="body_header">
                                <p className="m-0 title">Kiosk</p>
                                <div className="d-flex gap-2">
                                    <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(false)}>Kiosk list</p>
                                </div>
                            </div>
                            {/* <!-- add button start --> */}
                            <div className="d-md-flex justify-content-between my-4">
                                <div className="d-md-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search kiosk name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                    {userData?.role_id !== 3 && <StoreTypeAhead
                                        className="dropdown_wrap"
                                        onChange={onStoreChange}
                                        id="StoreTypeAhead"
                                        style={{ margin: '10px' }}
                                        placeholder="Select the store"
                                        isMulti={false}
                                        defaultValue={selectedStoreOption}
                                        disabled
                                    />}
                                    {(selectedStoreOption?.value || userData?.role_id === 3) && <EdgeTypeAhead
                                        className="dropdown_wrap"
                                        id="edgeTypeAhead"
                                        style={{ margin: '10px' }}
                                        placeholder="Select the edge"
                                        isMulti={false}
                                        onChange={onEdgeChange}
                                        disabled
                                        selectedStoreOption={selectedStoreOption}
                                        defaultValue={selectedEdgeOption}
                                        value={(selectedStoreOption?.value || selectedEdgeOption) ? selectedEdgeOption : []}
                                    />}
                                </div>
                                <button className="add_btn" onClick={(handleAddButtonClick)}><p className="m-0">Add</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                </button>
                            </div>
                            {/* <!-- add button end --> */}
                            {/* <!-- table start --> */}
                            <div className="table_bg">
                                <div className="table_body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Kiosk Name</th>
                                                <th scope="col">Checkout Type</th>
                                                <th scope="col">Ip Address</th>
                                                <th scope="col">Mac Address</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(kioskListData) && kioskListData?.map((obj, index) => (
                                                <tr key={obj?.id}>
                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                    <td>{obj?.name}</td>
                                                    <td>{obj?.checkout_type}</td>
                                                    <td>{obj?.ip_address ? obj?.ip_address : '-'}</td>
                                                    <td>{obj?.mac_address ? obj?.mac_address : '-'}</td>
                                                    <td>{obj?.is_active ? <p className="m-0 status_td">Active</p> : <p className="m-0 inactive_td">In Active</p>}</td>
                                                    <td>
                                                        <div className="action_td">
                                                            <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    strokeWidth="1.5" stroke="#1a9f53" width="16" height="16" >
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                                </svg>
                                                            </button>
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckChecked"
                                                                    checked={obj?.is_active}
                                                                    onClick={(e) => handleClick(obj)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {Array.isArray(kioskListData) && kioskListData?.length == 0 && (
                                                <tr className="tr-shadow">
                                                    <td colSpan="6" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {Array.isArray(kioskListData) && kioskListData?.length > 0 &&
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                    </div>}
                            </div>
                            {/* <!-- table end --> */}
                        </div>
                    </div>
                </div>}
                {showAddList && <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Kiosk</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 pointer-hover" onClick={() => handleBackButtonClick()}>Kiosk list</p>
                                        <p className="m-0">~</p>
                                        <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(true)}> {kioskDetails?.id ? "Update Kiosk" : "Create Kiosk"}</p>
                                    </div>
                                </div>
                                {/* <!-- Navigation Button end --> */}
                                {/* <!-- Kiosk Info Start --> */}
                                <div className="list_form mt-4">
                                    <div className="d-flex justify-content-between align-items-center"><p className="m-0 table_heading">Kiosk Information</p>
                                        <button className="add_btn" onClick={() => handleBackButtonClick()}><p className="m-0">Back</p></button>
                                    </div>
                                    <div className="row g-3 mt-3">
                                        {userData?.role_id !== 3 && <div className="col-md-6">
                                            <label className="form-label">Store</label>
                                            <StoreTypeAhead
                                                id="storeTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Store"
                                                isMulti={false}
                                                defaultValue={kioskDetails?.store_id}
                                                onChange={handleStoreSelect}
                                                disabled
                                            />
                                            {fieldChangedStatus?.store_id && kioskDetailsValidateField('store_id', kioskDetails?.store_id) && (
                                                <span className="text-danger">{kioskDetailsValidateField('store_id', kioskDetails?.store_id)}</span>
                                            )}
                                        </div>}
                                        {(kioskDetails?.store_id?.value || userData?.role_id === 3) && <div className="col-md-6">
                                            <label className="form-label">Edge</label>
                                            <EdgeTypeAhead
                                                id="edgeTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Edge"
                                                isMulti={false}
                                                defaultValue={kioskDetails?.cv_edge_id}
                                                onChange={handleEdgeSelect}
                                                disabled
                                                selectedStoreOption={kioskDetails?.store_id}
                                            />
                                            {fieldChangedStatus?.cv_edge_id && kioskDetailsValidateField('cv_edge_id', kioskDetails?.cv_edge_id) && (
                                                <span className="text-danger">{kioskDetailsValidateField('cv_edge_id', kioskDetails?.cv_edge_id)}</span>
                                            )}
                                        </div>}
                                        {(kioskDetails?.cv_edge_id?.value) && <div className="col-md-6">
                                            <label className="form-label">Camera</label>
                                            <CameraTypeAhead
                                                id="kisokTypeAhead"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Camera"
                                                isMulti={false}
                                                defaultValue={kioskDetails?.camera_id}
                                                onChange={handleCameraSelect}
                                                disabled
                                                selectedEdgeOption={kioskDetails?.cv_edge_id}
                                            />
                                            {fieldChangedStatus?.cv_edge_id && kioskDetailsValidateField('cv_edge_id', kioskDetails?.cv_edge_id) && (
                                                <span className="text-danger">{kioskDetailsValidateField('cv_edge_id', kioskDetails?.cv_edge_id)}</span>
                                            )}
                                        </div>}
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Kiosk Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter kiosk name"
                                                value={kioskDetails?.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                maxLength={50}
                                            />
                                            {fieldChangedStatus?.name && kioskDetailsValidateField('name', kioskDetails?.name) && (
                                                <span className="text-danger">{kioskDetailsValidateField('name', kioskDetails?.name)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Checkout type</label>
                                            <select
                                                className="form-select"
                                                value={kioskDetails?.checkout_type}
                                                onChange={(e) => handleFieldChange('checkout_type', e.target.value)}
                                            >
                                                <option value="">Select an option</option>
                                                <option value="self_checkout">Self checkout</option>
                                                <option value="traditional_checkout">Traditional checkout</option>
                                            </select>
                                            {fieldChangedStatus?.checkout_type && kioskDetailsValidateField('checkout_type', kioskDetails?.checkout_type) && (
                                                <span className="text-danger">{kioskDetailsValidateField('checkout_type', kioskDetails?.checkout_type)}</span>
                                            )}
                                        </div>
                                        {kioskDetails?.checkout_type === 'self_checkout' && <>
                                            <div className="col-md-6">
                                                <label className="form-label">Mac Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Mac Address"
                                                    value={kioskDetails?.mac_address}
                                                    onChange={(e) => handleFieldChange('mac_address', e.target.value)}
                                                />
                                                {fieldChangedStatus?.mac_address && kioskDetailsValidateField('mac_address', kioskDetails?.mac_address) && (
                                                    <span className="text-danger">{kioskDetailsValidateField('mac_address', kioskDetails?.mac_address)}</span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Ip Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Ip Address"
                                                    value={kioskDetails?.ip_address}
                                                    onChange={(e) => handleFieldChange('ip_address', e?.target?.value)}
                                                    maxLength={50}
                                                />
                                                {fieldChangedStatus?.ip_address && kioskDetailsValidateField('ip_address', kioskDetails?.ip_address) && (
                                                    <span className="text-danger">{kioskDetailsValidateField('ip_address', kioskDetails?.ip_address)}</span>
                                                )}
                                            </div></>}
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Kiosk unique id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter the kiosk unique id"
                                                value={kioskDetails?.kiosk_unique_id}
                                                onChange={(e) => handleFieldChange('kiosk_unique_id', e.target.value)}
                                                maxLength={10}
                                            />
                                            {fieldChangedStatus?.kiosk_unique_id && kioskDetailsValidateField('kiosk_unique_id', kioskDetails?.kiosk_unique_id) && (
                                                <span className="text-danger">{kioskDetailsValidateField('kiosk_unique_id', kioskDetails?.kiosk_unique_id)}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 col-4 mx-auto mt-4">
                                        <button onClick={handleSubmit} className="upload_btn" type="button">{kioskDetails?.id ? "Update" : "Create"}</button>
                                    </div>
                                </div>
                                {/* <!-- Kiosk Info end --> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => activeKiosk()}
                />}
            </div>
        </React.Fragment>
    )
}

export default Kiosk