
export const isValidEmail = (email) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    return validEmailRegex.test(email);
}

export const isFieldEmpty = (field) => {
    return (!field || field?.trim().length === 0)
}
export const isSelectEmpty = (field) => {
    return (Object?.keys(field)?.length === 0)
}

export const validatePriceValue = (name) => {
    let re = /^\d{0,8}(\.\d{1,2})?$/;
    return re.test(name);
}
export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
};
export const skuRegex = /^[A-Z]{3}-[0-9]{5}$/;
export const minLengthRegExp = /.{8,}/;
export const whiteSpaceValidation = (str) => {
    const validEmailRegex = RegExp('^[a-zA-Z0-9_]*$')
    return validEmailRegex.test(str);
};
export const onlyCharactersRegex = new RegExp(`^[a-zA-Z0-9 -]+$`);
export const onlyNumberRegex = new RegExp(`^[A-Z0-9.]+$`);
export const validateName = (name) => {
    let re = /^[a-zA-Z ]{3,30}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxName = (name) => {
    let re = /^[a-zA-Z ]{4,150}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxProduct = (name) => {
    let re = /^[a-zA-Z ]{4,50}$/;
    return re.test(String(name).toLowerCase());
}
export const validateSku = (name) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,50}$/;
    return re.test(name);
}
export const validateMaxAddress = (name) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,200}$/;
    return re.test(name);
}
export const validateMobileNumnber = (number) => {
    let re = /^(\+91[\-\s]?)?[0]?(91)?[789654]\d{9}$/;
    return re.test(number);
}
export const validateminLengthRegExp = (name) => {
    let re = /.{5,}/;
    return re.test(name);
}
export const validateminLength = (name) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validateminserverLengthRegExp = (name) => {
    let re = /.{3,}/;
    return re.test(name);
}
export const validateminLengthReg = (name) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validatemaxLengthZipReg = (name) => {
    let re = /^.{50,}$/;
    return re.test(name);
}
export const validatemaxLength = (name) => {
    let re = /^.{200,}$/;
    return re.test(name);
}
export const validatemaxLengthZipRegExp = (name) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const validatemaxLengthNumeberRegExp = (name) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const checkPercentage = (field) => {
    return (Math.ceil(field) > 100)
}

export const isCharacterValid = (character) => {
    const onlyCharactersRegex = new RegExp(`^[a-zA-Z ]+$`);
    return onlyCharactersRegex.test(character)
}

export const validateMobileNumber = (number) => {
    let re = /^(\+91[\-\s]?)?[0]?(91)?[789654]\d{9}$/;
    return re.test(number);
}

export const validatePanCard = (num) => {
    let pan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return pan.test(num);
}
export const validateIPV6Regex = (name) => {
    let re = /^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$/i;
    return re.test(name);
}
// Regular expression for MAC address
export const validateMacRegex = (name) => {
    let re = /^([0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2}$/;
    return re.test(name);
}
// Regular expression for IPv4 address
export const validateIPV4Regex = (name) => {
    let re = /^(\d{1,3}\.){3}\d{1,3}$/;
    return re.test(name);
}
export const storeDetailsValidateField = (field, value) => {

    switch (field) {
        case 'name':
            if (!value) {
                return 'Please enter the store name.';
            } else if (value.trim().length < 3) {
                return 'Store name minimum 3 character.'
            }
            break;
        case 'email':
            if (!value) {
                return 'Please enter the email id.';
            } else if (!isValidEmail(value)) {
                return 'Please enter valid email id'
            }
            break;
        case 'address':
            if (!value) {
                return 'Please enter the address.';
            } else if (value.trim().length < 3) {
                return 'Enter the address minimum 3 character.'
            }
            break;
        case 'city':
            if (!value) {
                return 'Please enter the city.';
            }
            break;
        case 'state':
            if (!value) {
                return 'Please enter the state.';
            }
            break;
        case 'country':
            if (!value) {
                return 'Please enter the country.';
            }
            break;
        case 'zipcode':
            if (!value) {
                return 'Please enter the zipcode.';
            }
            if (!onlyNumberRegex.test(value)) {
                return 'Zipcode field accepts only numeric.'
            }
            break;
        case 'contact_no':
            if (!value) {
                return 'Please enter the contact number.';
            } else if (!onlyNumberRegex.test(value)) {
                return 'Contact No field accepts only numeric.'
            } else if (value.trim().length < 10) {
                return 'Contact Number must be 10 digit.'
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const edgeDetailsValidateField = (field, value) => {

    switch (field) {
        case 'name':
            if (!value) {
                return 'Please enter the edge name.';
            } else if (value.trim().length < 3) {
                return 'Enter the edge name minimum 3 character.'
            }
            break;
        case 'mac_address':
            if (!value) {
                return 'Please enter the mac address';
            }
            else if (!validateMacRegex(value)) {
                return 'Please enter the valid mac address'
            }
            break;
        case 'ip_address':
            if (!value) {
                return 'Please enter the ip address';
            }
            else if (!validateIPV4Regex(value)) {
                return 'Please enter the valid ip address'
            }
            break;
        case 'store_id':
            if (!value) {
                return 'Please select the store';
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};
export const cameraDetailsValidateField = (field, value) => {

    switch (field) {
        case 'cv_edge_id':
            if (!value) {
                return 'Please select the edge';
            }
            break;
        case 'name':
            if (!value) {
                return 'Please enter the camera name.';
            } else if (value?.trim().length < 3) {
                return 'Camera name minimum 3 character.'
            }
            break;
        case 'address':
            if (!value) {
                return 'Please enter the address.';
            } else if (value.trim().length < 1) {
                return 'Enter the address minimum 1 character.'
            }
            break;
        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const kioskDetailsValidateField = (field, value) => {

    switch (field) {
        case 'store_id':
            if (!value) {
                return 'Please select the store';
            }
            break;
        case 'cv_edge_id':
            if (!value) {
                return 'Please select the edge';
            }
            break;
        case 'camera_id':
            if (!value) {
                return 'Please select the camera';
            }
            break;
        case 'name':
            if (!value) {
                return 'Please enter the kisok name.';
            } else if (value.trim().length < 3) {
                return 'Kisok name minimum 3 character.'
            }
            break;
        case 'mac_address':
            if (!value) {
                return 'Please enter the mac address';
            }
            else if (!validateMacRegex(value)) {
                return 'Please enter the valid mac address'
            }
            break;
        case 'ip_address':
            if (!value) {
                return 'Please enter the ip address';
            }
            else if (!validateIPV4Regex(value)) {
                return 'Please enter the valid ip address'
            }
            break;
        case 'kiosk_unique_id':
            if (!value) {
                return 'Please enter the kiosk unique id.';
            }
            if (!onlyNumberRegex.test(value)) {
                return 'Kiosk Unique id field accepts only numeric.'
            }
            break;
        case 'checkout_type':
            if (!value) {
                return 'Please select the checkout type';
            }
            break;
        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};
export const categoryDetailsValidateField = (field, value) => {

    switch (field) {
        case 'store_id':
            if (!value) {
                return 'Please select the store';
            }
            break;
        case 'name':
            if (!value) {
                return 'Please enter the category name.';
            } else if (value.trim().length < 3) {
                return 'category name minimum 3 character.'
            }
            break;
        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};
export const adminProductDetailsValidateField = (field, value) => {

    switch (field) {
        case 'name':
            if (!value) {
                return 'Please enter the product name.';
            } else if (value.trim().length < 3) {
                return 'Product name minimum 3 character.'
            }
            break;
        case 'price':
            if (!value) {
                return 'Please enter the price.';
            }
            if (!onlyNumberRegex.test(value)) {
                return 'Price field accepts only numeric.'
            }
            break;
        case 'group_id':
            if (!value) {
                return 'Please enter the group id.';
            }
            break;
        case 'training_id':
            if (!value) {
                return 'Please enter the training id.';
            }
            if (!onlyNumberRegex.test(value)) {
                return 'Training id field accepts only numeric.'
            }
            break;
        case 'store_id':
            if (!value) {
                return 'Please select the store';
            }
            break;
        case 'category_id':
            if (!value) {
                return 'Please select the category';
            }
            break;
        case 'is_common':
            if (!value) {
                return 'Please select the common';
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};
export const vendorProductDetailsValidateField = (field, value) => {

    switch (field) {
        case 'name':
            if (!value) {
                return 'Please enter the product name.';
            } else if (value.trim().length < 3) {
                return 'Product name minimum 3 character.'
            }
            break;
        case 'price':
            if (!value) {
                return 'Please enter the price.';
            }
            if (!onlyNumberRegex.test(value)) {
                return 'Price field accepts only numeric.'
            }
            break;
        case 'group_id':
            if (!value) {
                return 'Please enter the group id.';
            }
            break;
        case 'store_id':
            if (!value) {
                return 'Please select the store';
            }
            break;
        case 'category_id':
            if (!value) {
                return 'Please select the category';
            }
            break;
        case 'is_common':
            if (!value) {
                return 'Please select the common';
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};
export const adminOnboardDetailsValidateField = (field, value) => {

    switch (field) {
        case 'store_id':
            if (!value) {
                return 'Please select the store';
            }
            break;
        case 'first_name':
            if (!value) {
                return 'Please enter the first name.';
            } else if (value.trim().length < 3) {
                return 'First name minimum 3 character.'
            }
            break;
        case 'last_name':
            if (!value) {
                return 'Please enter the last name.';
            }
            break;
        case 'email':
            if (!value) {
                return 'Please enter the email id.';
            } else if (!isValidEmail(value)) {
                return 'Please enter valid email id'
            }
            break;
        case 'address':
            if (!value) {
                return 'Please enter the address.';
            } else if (value.trim().length < 3) {
                return 'Enter the address minimum 3 character.'
            }
            break;
        case 'city':
            if (!value) {
                return 'Please enter the city.';
            }
            break;
        case 'state':
            if (!value) {
                return 'Please enter the state.';
            }
            break;
        case 'country':
            if (!value) {
                return 'Please enter the country.';
            }
            break;
        case 'zipcode':
            if (!value) {
                return 'Please enter the zipcode.';
            }
            if (!onlyNumberRegex.test(value)) {
                return 'Zipcode field accepts only numeric.'
            }
            break;
        case 'contact_no':
            if (!value) {
                return 'Please enter the contact number.';
            } else if (!onlyNumberRegex.test(value)) {
                return 'Contact No field accepts only numeric.'
            } else if (value.trim().length < 10) {
                return 'Contact Number must be 10 digit.'
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};