import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Actions } from "../redux/action"
import { getLoginData } from "../redux/reducer/loginReducer"
import { isValidEmail } from "../utils/Validation"
import MESSAGE from "../constant/String"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import loginImage from '../asset/img/login_img.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AppLoader from "../component/AppLoader"

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(getLoginData)
    const [formDetails, setFormDetails] = useState({ emailId: "", password: "" });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({ password: "", emailId: "" });
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (userData?.token) {
            navigate('/dashboard')
        }
    }, [navigate, userData])

    const handleChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_THE_EMAIL })
        } else if (!isValidEmail(event.target.value)) {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_VALID_EMAIL })
        } else {
            setError({ ...error, emailId: '' })
        }
        setFormDetails({ ...formDetails, emailId: event.target.value })
    }

    const handlePasswordChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, password: MESSAGE?.PLEASE_ENTER_THE_PASSWORD })
        } else {
            setError({ ...error, password: '' })
        }
        setFormDetails({ ...formDetails, password: event.target.value })
    }

    const OnSubmit = () => {
        const { emailId, password } = formDetails
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code !== 0) {
                toast.error(response?.response_message)
            } else {
                navigate("/dashboard")
            }
        }
        if (emailId === "") {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_THE_EMAIL })
        } else if (!isValidEmail(emailId)) {
            setError({ ...error, emailId: MESSAGE?.PLEASE_ENTER_VALID_EMAIL })
        } else if (formDetails?.password === "") {
            setError({ ...error, password: MESSAGE?.PLEASE_ENTER_THE_PASSWORD })
        } else {
            setLoading(true)
            dispatch(Actions.loginUser({ username: emailId, password: password }, callback))
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className="login_main_screen">
                <div className="card login_card">
                    <div className="row">
                        <div className="col-md-6 d-md-flex align-items-center d-none">
                            <img src={loginImage} className="img-fluid p-4" alt="Login" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            {/* login start */}
                            <div className="p-lg-0 px-md-5 p-4">
                                <p className="fs-24 f-sbold">LOGIN</p>
                                <p className="fs-14 f-reg">Please enter your email and password.</p>
                                <div className="email">
                                    <label className="form-label fs-16 f-sbold mb-3">Email</label>
                                    <input type="email" className="form-control select_options aisle_name" placeholder="Enter your email here" onChange={(e) => handleChange(e)} />
                                    {error?.emailId && <p className="text-danger">{error?.emailId}</p>}
                                </div>
                                <div className="password mt-4">
                                    <label className="form-label fs-16 f-sbold mb-3">Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            value={formDetails?.password}
                                            className="form-control select_options aisle_name small-input" // Added a custom class "small-input"
                                            placeholder="Enter your password here"
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                        <div onClick={togglePasswordVisibility} className="input-group-append">
                                            <span className="input-group-text" style={{ padding: "11px" }}> {/* Reduced padding */}
                                                <FontAwesomeIcon
                                                    icon={showPassword ? faEye : faEyeSlash}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    {error?.password && <p className="text-danger">{error?.password}</p>}
                                    <span className="mt-2 pointer-hover" onClick={() => navigate('/forgetPassword')}>Forget Password</span>
                                </div>
                                <button className="btn login_btn mt-5" type="button" onClick={() => OnSubmit()}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login