import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_VENDOR_PRODUCT, UPDATE_VENDOR_PRODUCT,DELETE_VENDOR_PRODUCT,GET_VENDOR_PRODUCT,ACTIVE_VENDOR_PRODUCT } from '../action/vendorProductAction';

export function* getVendorProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_VENDOR_PRODUCT, params)
        yield put(Actions.getVendorProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getVendorProductFailure(error))
    }
}
export function* createVendorProduct({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_VENDOR_PRODUCT, params)
        yield put(Actions.createVendorProductSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createVendorProductFailure(error))
    }
}
export function* updateVendorProduct({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_VENDOR_PRODUCT, params)
        yield put(Actions.updateVendorProductSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateVendorProductFailure(error))
    }
}
export function* deleteVendorProduct({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_VENDOR_PRODUCT, params)
        yield put(Actions.deleteVendorProductSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteVendorProductFailure(error))
    }
}
export function* activeVendorProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_VENDOR_PRODUCT, params)
        yield put(Actions.activeVendorProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeVendorProductFailure(error))
    }
}
export default function* vendorProductWatcher() {
    yield all([
        takeLatest(GET_VENDOR_PRODUCT, getVendorProduct),
        takeLatest(CREATE_VENDOR_PRODUCT, createVendorProduct),
        takeLatest(UPDATE_VENDOR_PRODUCT, updateVendorProduct),
        takeLatest(DELETE_VENDOR_PRODUCT, deleteVendorProduct),
        takeLatest(ACTIVE_VENDOR_PRODUCT, activeVendorProduct),

    ]);
}
