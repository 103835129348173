import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { journeyList } from "../redux/reducer/dashboardReducer";
import { useSelector } from 'react-redux';
import { MonthLabels, WeekLabels, dayLabels, filterByMonth, filterByTime, filterByWeek, filterByday, filterBydayWithLastWeek, lastWeekDay, timeLabelIn12 } from './SuspiciousOverview';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Suspicious Overview',
    },
  },
};

const LineChart = ({ filter }) => {
  const journeyListData = useSelector(journeyList)
  let graphData;
  let xlabels;
  switch (filter) {
    case "current_day":
    case "previous_day":
      graphData = filterByTime(journeyListData);
      xlabels = timeLabelIn12
      break;
    case "current_week":
    case "previous_week":
      graphData = filterByday(journeyListData);
      xlabels = dayLabels
      break;
    case "current_month":
    case "previous_month":
      graphData = filterByWeek(journeyListData);
      xlabels = WeekLabels
      break;
    case "current_year":
    case "previous_year":
      graphData = filterByMonth(journeyListData);
      xlabels = MonthLabels
      break;
    default:
      graphData = filterBydayWithLastWeek(journeyListData);
      xlabels = lastWeekDay
      break;
  }
  const LineData = {
    labels: xlabels,
    datasets: [
      {
        label: 'Total Journey',
        data: graphData?.map((obj) => obj?.total_journey),
        borderColor: 'rgb(15, 10, 222)',
        backgroundColor: 'rgba(15, 10, 222, 0.3)',
        cubicInterpolationMode: 'monotone',
      },
      {
        label: 'Good Journey',
        data: graphData?.map((obj) => obj?.good_journey),
        borderColor: 'rgb(178, 222, 39)',
        backgroundColor: 'rgba(178, 222, 39, 0.3)',
        cubicInterpolationMode: 'monotone',
      },
      {
        label: 'Bad Journey',
        data: graphData?.map((obj) => obj?.bad_journey),
        borderColor: 'rgb(255, 76, 48)',
        backgroundColor: 'rgba(255, 76, 48, 0.3)',
        cubicInterpolationMode: 'monotone',
      }
    ],
  };
  return <Line options={options} data={LineData} />

};
export default LineChart;
