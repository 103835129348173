import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_CAMERA, UPDATE_CAMERA,DELETE_CAMERA,GET_CAMERA,ACTIVE_CAMERA } from '../action/cameraAction';

export function* getCamera({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_CAMERA, params)
        yield put(Actions.getCameraSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getCameraFailure(error))
    }
}
export function* createCamera({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.CREATE_CAMERA, params)
        yield put(Actions.createCameraSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.createCameraFailure(error))
    }
}
export function* updateCamera({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.UPDATE_CAMERA, params)
        yield put(Actions.updateCameraSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.(error)
        yield put(Actions.updateCameraFailure(error))
    }
}
export function* deleteCamera({ params, callback }) {

    try {
        const response = yield doPost(END_POINTS.DELETE_CAMERA, params)
        yield put(Actions.deleteCameraSuccess(response?.response))
        callback?.(response)

    } catch (error) {
        callback?.( error )
        yield put(Actions.deleteCameraFailure(error))
    }
}
export function* activeCamera({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.ACTIVE_CAMERA, params)
        yield put(Actions.activeCameraSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.activeCameraFailure(error))
    }
}
export default function* cameraWatcher() {
    yield all([
        takeLatest(GET_CAMERA, getCamera),
        takeLatest(CREATE_CAMERA, createCamera),
        takeLatest(UPDATE_CAMERA, updateCamera),
        takeLatest(DELETE_CAMERA, deleteCamera),
        takeLatest(ACTIVE_CAMERA, activeCamera),

    ]);
}
