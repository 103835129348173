import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { GET_JOURNEY,GET_HIGH_VALUE_PRODUCT,GET_TIME_ANALYSIS, GET_TOTAL_SAVED_PRODUCT, GET_THEFT_ANALYSIS, GET_PREVENTED_PRODUCT_ANALYTIC } from '../action/dashboardAction';

export function* getJourney({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_JOURNEY, params)
        yield put(Actions.getJourneySuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getJourneyFailure(error))
    }

}
export function* getTheftAnalysis({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_THEFT_ANALYSIS, params)
        yield put(Actions.getTheftAnalysisSuccess(response?.response))
    } catch (error) {
        yield put(Actions.getTheftAnalysisFailure(error))
    }
}
export function* getHighValueProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_HIGH_VALUE_PRODUCT, params)
        yield put(Actions.getHighValueProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getHighValueProductFailure(error))
    }

    try {
        const response = yield doGet(END_POINTS.GET_HIGH_VOLUME_PRODUCT, params)
        yield put(Actions.getHighVolumeProductSuccess(response?.response))
    } catch (error) {
        yield put(Actions.getHighVolumeProductFailure(error))
    }
}
export function* getTimeAnalysis({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_TIME_ANALYSIS, params)
        yield put(Actions.getTimeAnalysisProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getTimeAnalysisProductFailure(error))
    }
}
export function* getTotalSavedProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_TOTAL_SAVED_PRODUCT, params)
        yield put(Actions.getTotalSavedProductSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getTotalSavedProductFailure(error))
    }

    try {
        const response = yield doGet(END_POINTS.GET_TOTAL_SAVED_PRICE, params)
        yield put(Actions.getTotalSavedPriceSuccess(response?.response))
    } catch (error) {
        yield put(Actions.getTotalSavedPriceFailure(error))
    }
}
export function* getPreventedProduct({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.GET_PREVENTED_PRODUCT, params)
        yield put(Actions.getPreventedProductAnalyticSuccess(response?.response))
        callback?.(response)
    } catch (error) {
        callback?.(error)
        yield put(Actions.getPreventedProductAnalyticFailure(error))
    }
}
export default function* dashboardWatcher() {
    yield all([
        takeLatest(GET_JOURNEY, getJourney),
        takeLatest(GET_THEFT_ANALYSIS,getTheftAnalysis),
        takeLatest(GET_HIGH_VALUE_PRODUCT, getHighValueProduct),
        takeLatest(GET_TIME_ANALYSIS, getTimeAnalysis),
        takeLatest(GET_TOTAL_SAVED_PRODUCT, getTotalSavedProduct),
        takeLatest(GET_PREVENTED_PRODUCT_ANALYTIC, getPreventedProduct),
    ]);
}
