const protocol = window.location.protocol || 'http:';
const hostname = window.location.hostname || 'localhost';
let port = window.location.port;

if (!port) {
    port = protocol === 'http:' ? 80 : protocol === 'https:' ? 443 : ''
}
let API_BASE_URL = 'http://localhost:9000'
let SOCKET_IO_PATH = "ws://localhost:9000"
let SOCKET_URL = "ws/socket-server"
if (hostname === 'lp-admin-e2e-dev.cvi.ai') {
    API_BASE_URL = 'https://lp-api-e2e-dev.cvi.ai'
    SOCKET_IO_PATH = "wss://lp-api-e2e-dev.cvi.ai"
    SOCKET_URL = "ws/socket-server"
} else if (hostname === 'lp-admin-gcp.cvi.ai') {
    API_BASE_URL = 'https://lp-api-gcp.cvi.ai'
    SOCKET_IO_PATH = "wss://lp-api-gcp.cvi.ai"
    SOCKET_URL = "ws/socket-server"
}
const END_POINTS = {
    API_BASE_URL: API_BASE_URL,
    SOCKET_IO_PATH: SOCKET_IO_PATH,
    SOCKET_URL: SOCKET_URL,
    LOGIN: '/login',
    LOGOUT: '/logout',
    FORGET_PASSWORD: '/forgotPassword',
    RESET_PASSWORD: '/resetPassword',
    VERIFY_OTP: '/verifyOtp',
    CHANGE_PASSWORD: '/changePassword',
    //STORE
    GET_STORE: '/storeList',
    CREATE_STORE: '/storeOnboarding',
    UPDATE_STORE: '/storeUpdate',
    DELETE_STORE: '/storeStatus',
    ACTIVE_STORE: '/storeActiveList',
    //EDGE
    GET_EDGE: '/edgeList',
    CREATE_EDGE: '/edgeOnboarding',
    UPDATE_EDGE: '/edgeUpdate',
    DELETE_EDGE: '/edgeStatus',
    ACTIVE_EDGE: '/edgeActiveList',


    //CAMERA
    GET_CAMERA: '/cameraList',
    CREATE_CAMERA: '/cameraOnboarding',
    UPDATE_CAMERA: '/cameraUpdate',
    DELETE_CAMERA: '/cameraStatus',
    ACTIVE_CAMERA: '/cameraActiveList',


    //CATEGORY
    GET_CATEGORY: '/categoryList',
    CREATE_CATEGORY: '/categoryOnboarding',
    UPDATE_CATEGORY: '/categoryUpdate',
    DELETE_CATEGORY: '/categoryStatus',
    ACTIVE_CATEGORY: '/categoryActiveList',


    //ADMIN_PRODUCT
    GET_ADMIN_PRODUCT: '/adminProductList',
    CREATE_ADMIN_PRODUCT: '/adminProductOnboarding',
    UPDATE_ADMIN_PRODUCT: '/adminProductUpdate',
    DELETE_ADMIN_PRODUCT: '/adminProductStatus',
    ACTIVE_ADMIN_PRODUCT: '/adminProductActiveList',

    //VENDOR_PRODUCT
    GET_VENDOR_PRODUCT: '/vendorProductList',
    CREATE_VENDOR_PRODUCT: '/vendorProductOnboarding',
    UPDATE_VENDOR_PRODUCT: '/vendorProductUpdate',
    DELETE_VENDOR_PRODUCT: '/vendorProductStatus',
    ACTIVE_VENDOR_PRODUCT: '/vendorProductActiveList',

    //KIOSK
    GET_KIOSK: '/kioskList',
    CREATE_KIOSK: '/kioskOnboarding',
    UPDATE_KIOSK: '/kioskUpdate',
    DELETE_KIOSK: '/kioskStatus',
    ACTIVE_KIOSK: '/kioskActiveList',

    //ADMIN
    GET_ADMIN_ON_BOARD: '/adminList',
    CREATE_ADMIN_ON_BOARD: '/adminOnboarding',
    UPDATE_ADMIN_ON_BOARD: '/adminUpdate',
    DELETE_ADMIN_ON_BOARD: '/adminStatus',
    ACTIVE_ADMIN_ON_BOARD: '/adminActiveList',

    //NOTIFICATION
    GET_NOTIFICATION: '/notificationList',
    CREATE_NOTIFICATION: '/notificationOnboarding',
    UPDATE_NOTIFICATION: '/notificationUpdate',
    DELETE_NOTIFICATION: '/notificationStatus',
    ACTIVE_NOTIFICATION: '/notificationActiveList',

    //DASHBOARD
    GET_JOURNEY: '/journeyDashboard',
    GET_THEFT_ANALYSIS: '/theftAnalysisDashboard',
    GET_HIGH_VALUE_PRODUCT: '/highValueProductDashboard',
    GET_HIGH_VOLUME_PRODUCT: '/highVolumeProductDashboard',
    GET_TIME_ANALYSIS: '/timeAnalysisDashboard',
    GET_TOTAL_SAVED_PRODUCT: '/totalSaveProductDashboard',
    GET_TOTAL_SAVED_PRICE: '/totalSavePriceDashboard',
    GET_PREVENTED_PRODUCT: '/preventedProductAnalytic',

}

export default END_POINTS