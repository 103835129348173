import { Actions } from '../action';

const initialState = {
    isLoading: false,
    notificationList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const notificationReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_NOTIFICATION: {
            return commonState(state);
        }
        case Actions.GET_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                notificationList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_NOTIFICATION_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_NOTIFICATION: {
            return commonState(state);
        }
        case Actions.CREATE_NOTIFICATION_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_NOTIFICATION_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_NOTIFICATION: {
            return commonState(state);
        }
        case Actions.UPDATE_NOTIFICATION_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_NOTIFICATION_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_NOTIFICATION: {
            return commonState(state);
        }
        case Actions.DELETE_NOTIFICATION_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_NOTIFICATION_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_NOTIFICATION: {
            return commonState(state);
        }
        case Actions.ACTIVE_NOTIFICATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                notificationActiveList: action?.data,
                error: false
            };
        }
        case Actions.ACTIVE_NOTIFICATION_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const notificationList = (state) => state?.NOTIFICATION?.notificationList;
export const notificationActiveList = (state) => state?.NOTIFICATION?.notificationActiveList;
export const totalItemCount = (state) => state?.NOTIFICATION?.totalCount;
export const notificationListLoadingSelector = state => state?.NOTIFICATION?.isLoading;


