import { Actions } from '../action';

const initialState = {
    isLoading: false,
    kioskList: [],
    kioskActiveList: [],
    error: false,
    totalCount: 0,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const kioskReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_KIOSK: {
            return commonState(state);
        }
        case Actions.GET_KIOSK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                kioskList: action?.data?.result,
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_KIOSK_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CREATE_KIOSK: {
            return commonState(state);
        }
        case Actions.CREATE_KIOSK_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CREATE_KIOSK_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.UPDATE_KIOSK: {
            return commonState(state);
        }
        case Actions.UPDATE_KIOSK_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.UPDATE_KIOSK_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.DELETE_KIOSK: {
            return commonState(state);
        }
        case Actions.DELETE_KIOSK_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.DELETE_KIOSK_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.ACTIVE_KIOSK: {
            return commonState(state);
        }
        case Actions.ACTIVE_KIOSK_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                kioskActiveList: action?.data,
                error: false
            };
        }
        case Actions.ACTIVE_KIOSK_FAILURE: {
            return commonFailer(state, action);
        }
        default:
            return state;
    }
};



export const kioskList = (state) => state?.KIOSK?.kioskList;
export const kioskActiveList = (state) => state?.KIOSK?.kiosActivekList;
export const totalItemCount = (state) => state?.KIOSK?.totalCount;
export const kioskListLoadingSelector = state => state?.KIOSK?.isLoading;


