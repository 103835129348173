import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../redux/action';
import { adminProductDetailsValidateField } from "../utils/Validation";
import { adminProductList, totalItemCount } from '../redux/reducer/adminProductReducer';
import { toast } from "react-toastify";
import { PaginationComponent } from "../component/Pagination";
import AppLoader from "../component/AppLoader";
import { DeclinePopup } from "../component/DeclinePopup";
import CategoryTypeAhead from "../component/CategoryTypeAhead";
import { categoryActiveList } from '../redux/reducer/categoryReducer';
import { getLoginData } from "../redux/reducer/loginReducer";
import MESSAGE from "../constant/String";

const AdminProduct = () => {
    const dispatch = useDispatch()
    const adminProductListData = useSelector(adminProductList)
    const totalCount = useSelector(totalItemCount)
    const userData = useSelector(getLoginData)
    const activeCategoryList = useSelector(categoryActiveList)
    const [isLoading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [showAddList, setShowAddList] = useState(false);
    const [isOpenDeclineModal, setOpenDeclineModal] = useState(false);
    const [itemObj, setItemObj] = useState('')
    const [selectCategory, setSelectedCategory] = useState()
    const [selectSearch, setSelectedSearch] = useState()
    const [adminProductDetails, setAdminProductDetails] = useState({
        name: "",
        price: "",
        group_id: "",
        training_id: "",
        category_id: "",
        // store_id: "",
        // is_common: ""
    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        price: false,
        group_id: false,
        training_id: false,
        category_id: false,
        // store_id: false,
        // is_common: false
    });

    const getAdminProductList = useCallback(() => {
        const callback = () => {
            setLoading(false)
        }
        let params = {
            page: pageNumber,
            page_size: MESSAGE?.ITEM_COUNT_PER_PAGE,
            category_id: selectCategory?.value,
            search: selectSearch
        }
        setLoading(true)
        dispatch(Actions.getAdminProduct(params, callback))
    }, [dispatch, pageNumber, selectCategory?.value, selectSearch])

    useEffect(() => {
        getAdminProductList()
        // dispatch(Actions.activeStore())
        // dispatch(Actions.activeCategory())
    }, [dispatch, getAdminProductList])

    const handleFieldChange = (field, value) => {
        setAdminProductDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleAddButtonClick = () => {
        setShowAddList(true);
        setAdminProductDetails({
            name: "",
            price: "",
            group_id: "",
            training_id: "",
            category_id: "",
            // is_common: "",
            // store_id: "",

        })
    };
    const handleBackButtonClick = () => {
        setShowAddList(false);
        setAdminProductDetails({
            name: "",
            price: "",
            group_id: "",
            training_id: "",
            // store_id: "",
            category_id: "",
            // is_common: ""

        })
        setFieldChangedStatus(() => ({
            name: false,
            price: false,
            group_id: false,
            training_id: false,
            category_id: false,
            // store_id: false,
            // is_common: false
        }));
    };
    const onEdit = (obj) => {
        const { name, price, group_id, training_id, category, id } = obj;
        const selectedCategoryItem = activeCategoryList?.map(d => ({ label: d?.name, value: d?.id }))?.find(({ value }) => value === category);
        setAdminProductDetails({
            id: id,
            name: name,
            price: price,
            group_id: group_id,
            training_id: training_id,
            category_id: selectedCategoryItem,
        });
        setShowAddList(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        for (const field in adminProductDetails) {
            if (field === 'category_id') {
                newErrors[field] = false;
                continue;
            }
            if (adminProductDetailsValidateField(field, adminProductDetails?.[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            setLoading(true)
            const callback = (response) => {
                setLoading(false)
                if (response?.response_code === 0) {
                    handleBackButtonClick()
                    getAdminProductList();
                    toast.success(response?.response_message)
                } else {
                    toast.error(response?.response_message)
                }
            };
            let params = {
                id: adminProductDetails?.id,
                name: adminProductDetails?.name,
                price: adminProductDetails?.price,
                training_id: adminProductDetails?.training_id,
                group_id: adminProductDetails?.group_id,
                // store_id: adminProductDetails?.store_id?.value,

                category_id: adminProductDetails?.category_id?.value,
                // is_common: adminProductDetails?.is_common
            }
            if (adminProductDetails?.id) {
                dispatch(Actions.updateAdminProduct(params, callback))
            } else {
                delete params.id;
                dispatch(Actions.createAdminProduct(params, callback))
            }

        }
    };
    const activeAdminProduct = () => {
        setLoading(true)
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                onCloseModel()
                handleBackButtonClick();
                getAdminProductList();
                toast.success(response?.response_message)
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            "adminProduct_id": itemObj?.id,
            "is_active": itemObj?.is_active ? "False" : "True"
        }
        dispatch(Actions.deleteAdminProduct(params, callback))
    }
    const onCloseModel = () => {
        setItemObj('');
        setOpenDeclineModal(false)
    };
    const handleClick = (obj) => {
        setItemObj(obj);
        setOpenDeclineModal(true);
    };
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const handleCategorySelect = (selected) => {
        setAdminProductDetails({ ...adminProductDetails, 'category_id': selected })

    };
    const onCategoryChange = (selected) => {
        setPageNumber(1)
        setSelectedCategory(selected)
        // getAdminProductList(selected)
    };
    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    return (
        <React.Fragment>
            <div className="vision_dashboard">
                {isLoading && <AppLoader />}
                {!showAddList && <div className="vision_dashboard_content">
                    <div className="right_side right_side_mid right_side_cover ">
                        <div className="body_content">
                            <div className="body_header">
                                <p className="m-0 title">Admin Product</p>
                                <div className="d-flex gap-2">
                                    <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(false)}>Admin Product list</p>
                                </div>
                            </div>

                            {/* <!-- add button start --> */}
                            <div className="d-md-flex justify-content-between my-4">
                                <div className="d-md-flex gap-4">
                                    <div className="d-flex align-items-center search_border">
                                        <div className="search_icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="22" className="ps-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </div>
                                        <input className="search_input" type="search" placeholder="Search product name" aria-label="Search" onChange={onSearchChange} />
                                    </div>
                                    <CategoryTypeAhead
                                        className="dropdown_wrap"
                                        id="categoryTypeAhead"
                                        style={{ margin: '10px' }}
                                        placeholder="Select the Category"
                                        isMulti={false}
                                        onChange={onCategoryChange}
                                        defaultValue={selectCategory}
                                    // disabled
                                    />
                                </div>
                                {userData?.role_id === 1 && <button className="add_btn" onClick={(handleAddButtonClick)}><p className="m-0">Add</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width="20">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                </button>}
                            </div>
                            {/* <!-- add button end --> */}
                            {/* <!-- table start --> */}
                            <div className="table_bg">
                                <div className="table_body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(adminProductListData) && adminProductListData?.map((obj, index) => (
                                                <tr key={obj?.id}>
                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (index + 1)}</td>
                                                    <td>{obj?.name}</td>
                                                    <td>{obj?.price}</td>
                                                    <td>{obj?.is_active ? <p className="m-0 status_td">Active</p> : <p className="m-0 inactive_td">In Active</p>}</td>
                                                    <td>
                                                        <div className="action_td">
                                                            {userData?.role_id !== 1 &&
                                                                <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                        strokeWidth="1.5" stroke="#1a9f53" width="16" height="16">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                    </svg>
                                                                </button>
                                                            }

                                                            {userData?.role_id === 1 && <>
                                                                <button onClick={() => onEdit(obj)} className="edit_btn">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                        strokeWidth="1.5" stroke="#1a9f53" width="16" height="16" >
                                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                                    </svg>
                                                                </button>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id="flexSwitchCheckChecked"
                                                                        checked={obj?.is_active}
                                                                        onClick={(e) => handleClick(obj)}
                                                                    />
                                                                </div></>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            {Array.isArray(adminProductListData) && adminProductListData?.length == 0 && (
                                                <tr className="tr-shadow">
                                                    <td colSpan="6" className="text-center">
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {Array.isArray(adminProductListData) && adminProductListData?.length > 0 &&
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalCount} pageNumber={pageNumber} setpage={setPageNumber} />
                                    </div>}
                            </div>
                            {/* <!-- table end --> */}
                        </div>
                    </div>
                </div>}
                {showAddList && <div className="vision_dashboard">
                    <div className="vision_dashboard_content">
                        <div className="right_side right_side_mid right_side_cover ">
                            <div className="body_content">
                                {/* <!-- Navigation Button start --> */}
                                <div className="body_header">
                                    <p className="m-0 title">Admin Product</p>
                                    <div className="d-flex gap-2">
                                        <p className="m-0 pointer-hover" onClick={() => handleBackButtonClick()}>Admin Product list</p>
                                        <p className="m-0">~</p>
                                        <p className="m-0 title_active pointer-hover" onClick={() => setShowAddList(true)}> {adminProductDetails?.id ? "Update AdminProduct" : "Create AdminProduct"}</p>
                                    </div>
                                </div>
                                {/* <!-- Navigation Button end --> */}
                                {/* <!-- AdminProduct Info Start --> */}
                                <div className="list_form mt-4">
                                    <div className="d-flex justify-content-between align-items-center"><p className="m-0 table_heading">Admin Product Information</p>
                                        <button className="add_btn" onClick={() => handleBackButtonClick()}><p className="m-0">Back</p></button>
                                    </div>
                                    <div className="row g-3 mt-3">
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Product Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter product name"
                                                value={adminProductDetails?.name}
                                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                                maxLength={50}
                                                disabled={userData?.role_id !== 1}
                                            />
                                            {fieldChangedStatus?.name && adminProductDetailsValidateField('name', adminProductDetails?.name) && (
                                                <span className="text-danger">{adminProductDetailsValidateField('name', adminProductDetails?.name)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Price</label>
                                            <input
                                                type="text" maxLength="10"
                                                className="form-control"
                                                placeholder="Enter admin product price"
                                                value={adminProductDetails?.price}
                                                onChange={(e) => handleFieldChange('price', e.target.value)}
                                                disabled={userData?.role_id !== 1}
                                            />
                                            {fieldChangedStatus?.price && adminProductDetailsValidateField('price', adminProductDetails?.price) && (
                                                <span className="text-danger">{adminProductDetailsValidateField('price', adminProductDetails?.price)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Group Id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter the group Id"
                                                value={adminProductDetails?.group_id}
                                                onChange={(e) => handleFieldChange('group_id', e.target.value)}
                                                maxLength={50}
                                                disabled={userData?.role_id !== 1}
                                            />
                                            {fieldChangedStatus?.group_id && adminProductDetailsValidateField('group_id', adminProductDetails?.group_id) && (
                                                <span className="text-danger">{adminProductDetailsValidateField('group_id', adminProductDetails?.group_id)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="inputEmail4" className="form-label">Training Id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter the training Id"
                                                value={adminProductDetails?.training_id}
                                                onChange={(e) => handleFieldChange('training_id', e.target.value)}
                                                maxLength={50}
                                                disabled={userData?.role_id !== 1}
                                            />
                                            {fieldChangedStatus?.training_id && adminProductDetailsValidateField('training_id', adminProductDetails?.training_id) && (
                                                <span className="text-danger">{adminProductDetailsValidateField('training_id', adminProductDetails?.training_id)}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Category</label>
                                            <CategoryTypeAhead
                                                id="categoryTypeAhead1"
                                                style={{ margin: '10px' }}
                                                placeholder="Select Category"
                                                isMulti={false}
                                                defaultValue={adminProductDetails?.category_id}
                                                onChange={handleCategorySelect}
                                                disabled={userData?.role_id !== 1}
                                            />
                                            {fieldChangedStatus?.category_id && adminProductDetailsValidateField('category_id', adminProductDetails?.category_id) && (
                                                <span className="text-danger">{adminProductDetailsValidateField('category_id', adminProductDetails?.category_id)}</span>
                                            )}
                                        </div>
                                    </div>
                                    {userData?.role_id === 1 && <div className="d-grid gap-2 col-4 mx-auto mt-4">
                                        <button onClick={handleSubmit} className="upload_btn" type="button">{adminProductDetails?.id ? "Update" : "Create"}</button>
                                    </div>}
                                </div>
                                {/* <!-- AdminProduct Info end --> */}
                            </div>
                        </div>
                    </div>
                </div>}
                {isOpenDeclineModal && <DeclinePopup
                    title={`Are you sure you want to ${itemObj?.is_active === true ? "Disable" : "Enable"} the ${itemObj?.name}?`}
                    onClose={() => onCloseModel()}
                    onSuccess={() => activeAdminProduct()}
                />}
            </div>
        </React.Fragment>
    )
}

export default AdminProduct