import { takeLatest, all, put } from 'redux-saga/effects';
import { doPost, doPostUnAuth, login } from '../../service';
import { Actions } from '../action/index';
import { LOGIN_USER,FORGET_PASSWORD_USER,RESET_PASSWORD_USER, VERIFY_OTP_USER,CHANGE_PASSWORD_USER,LOGOUT_USER } from '../action/loginAction';
import END_POINTS from '../../service/EndPoint';

export function* loginUser({params,callback}) {
  try {
    const response = yield login(END_POINTS.LOGIN, params)
    yield put(Actions.loginUserSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.loginUserFailure(error))
  }
}
export function* logOutUser({params,callback}) {
  try {
    const response = yield doPost(END_POINTS.LOGOUT, params)
    yield put(Actions.logOutUserSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.logOutUserFailure(error))
  }
}
export function* forgetPasswordUser({params,callback}) {
  try {
    const response = yield doPostUnAuth(END_POINTS.FORGET_PASSWORD, params)
    yield put(Actions.forgetPasswordUserSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.forgetPasswordUserFailure(error))
  }
}

export function* resetPasswordUser({params,callback}) {
  try {
    const response = yield doPostUnAuth(END_POINTS.RESET_PASSWORD, params)
    yield put(Actions.resetPasswordUserSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.resetPasswordUserFailure(error))
  }
}
export function* verifyOtpUser({params,callback}) {
  try {
    const response = yield doPostUnAuth(END_POINTS.VERIFY_OTP, params)
    yield put(Actions.verifyOtpUserSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.verifyOtpUserFailure(error))
  }
}
export function* changePasswordUser({params,callback}) {
  try {
    const response = yield doPost(END_POINTS.CHANGE_PASSWORD, params)
    yield put(Actions.changePasswordUserSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback(error)
    yield put(Actions.changePasswordUserFailure(error))
  }
}
export default function* loginWatcher() {
  yield all([
    takeLatest(LOGIN_USER, loginUser),
    takeLatest(LOGOUT_USER, logOutUser),
    takeLatest(FORGET_PASSWORD_USER, forgetPasswordUser),
    takeLatest(RESET_PASSWORD_USER, resetPasswordUser),
    takeLatest(CHANGE_PASSWORD_USER, changePasswordUser),
    takeLatest(VERIFY_OTP_USER, verifyOtpUser),
  ]);
}
