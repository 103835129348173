import { Actions } from '../action';

const initialState = {
    isLoading: false,
    token: null,
    user: null,
    userEmailId: null,
    error: false,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOGIN_USER: {
            return commonState(state);
        }
        case Actions.LOGIN_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                token: action?.data?.token,
                user: action?.data,
                error: false
            };
        }
        case Actions.LOGIN_USER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                token: null,
                user: null,
                error: action.error,
            };
        }
        case Actions.LOGOUT_USER: {
            return commonState(state);
        }
        case Actions.LOGOUT_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.LOGOUT_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.FORGET_PASSWORD_USER: {
            return commonState(state);
        }
        case Actions.FORGET_PASSWORD_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.FORGET_PASSWORD_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.RESET_PASSWORD_USER: {
            return commonState(state);
        }
        case Actions.RESET_PASSWORD_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.RESET_PASSWORD_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.VERIFY_OTP_USER: {
            return commonState(state);
        }
        case Actions.VERIFY_OTP_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.VERIFY_OTP_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CHANGE_PASSWORD_USER: {
            return commonState(state);
        }
        case Actions.CHANGE_PASSWORD_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CHANGE_PASSWORD_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.LOGIN_CHECK_DATA: {
            return {
                ...state,
                isLoading: false,
                userEmailId: action?.data,
                error: false
            };
        }
        default:
            return state;
    }
};

export const getLoginData = (state) => state?.USER?.user;
export const userEmailId = (state) => state?.USER?.userEmailId;

